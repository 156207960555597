<div class="wrapper">
    <div class="main-container">
        <div class="modal-header">
            <button class="close" (click)="closeEulaDialog()">+</button>
        </div>
        <div class="modal-body">
            <div class="svg-container"><img [src]="'assets/eula-graphic.svg'"></div>
            <div class="eula-header">{{'eula.endUserLicenseAgreement' | translate}}</div>
            <div class="eula-agreement-check">
                <div class="tc-container" (click)="togglerTCSelection()">
                    <input type="checkbox" class="tc-checked" name="isChecked" [(ngModel)]="isTCClicked">
                    <span class="text-regular" [innerHTML]="'eula.checkBoxEULAAgreement' | translate"></span>
                </div>
            </div>
    
        </div>
        <div class="modal-footer">
            <button class="footer-submit" (click)="acceptEula()" [disabled]="!isTCClicked || isSubmitted">{{'eula.confirm' | translate}}</button>
        </div>
    </div>
</div>