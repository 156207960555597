<div class="container">
  <div class="card">
    <div class="details">
      <div class="user-photo loading"></div>
      <div class="load-user-name loading"></div>
      <div class="load-address loading"></div>
    </div>
    <div class="call loading"></div>
  </div>
</div>
