import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { FetchInterceptorService } from './interceptors/fetch-interceptor.service';
import { AnalyticsService } from './services/analytics.service';
import { LoggingService } from './services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  window: Window;
  error = '';

  constructor(
    private authService: AuthService,
    private fetchInterceptor: FetchInterceptorService,
    private analyticsService: AnalyticsService
  ) {
    LoggingService.info('[AppComponent]: Initialising app...');
    this.analyticsService.configure();
    this.fetchInterceptor.init();
  }

  async ngOnInit(): Promise<void> {
    try {
      const userName = await this.authService.init();
      if (!userName) {
        return;
      }
      this.analyticsService.record('navigated-to-mitel-assistant');
    } catch (error) {
      this.error = error.message;
      LoggingService.error('[AppComponent]: Error in ngOnInit: ' + error);
    }
  }
}
