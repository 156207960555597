import { Component, OnInit, Output, EventEmitter, Input, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { AppUtils } from 'src/app/common/utils';
import { CloudAuthenticationService } from 'src/app/services/cloud-authentication.service';
import { LoggingService } from 'src/app/services/logging.service';
import * as VersionInfo from 'src/environments/version';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.less']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  @Output() reportIssueDialogEvt: EventEmitter<any> =  new EventEmitter();
  @Output() logOutEvt: EventEmitter<any> =  new EventEmitter();
  @Output() logInEvt: EventEmitter<any> =  new EventEmitter();
  @Output() closeDialog: EventEmitter<any> =  new EventEmitter();
  @Input() currentUser: any;
  @Input() isMobile = false;
  @Input() isCLEnabled = false;
  @ViewChild('elemSettings') settingsWin: TemplateRef<any>;
  get currentUserInitials(): string {
    return AppUtils.getInitials(this.currentUser?.msNameFull);
  }
  public flagLoggedin: boolean;
  get buildVersion(): string {
    return VersionInfo.VERSION.appVersion;
  }

  constructor(
    private clAuthSvc: CloudAuthenticationService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn();
    if ( !this.isMobile) { // No need of attaching or detaching the click event in case of mobile
      this.attachClickEvent();
    }
  }

  openReportIssueDialog(): void {
    this.reportIssueDialogEvt.emit();
  }

  logOut(): void {
    this.logOutEvt.emit();
  }

  login(): void {
    this.logInEvt.emit();
  }

  setStyles(opacity: boolean = false, top: boolean = false): void {
    if (opacity) {
      (this.settingsWin as any).nativeElement.style.opacity = '1';
    }
    if ( top) {
      (this.settingsWin as any).nativeElement.style.top = '10px !important';
    }
  }

  isLoggedIn(): void {
    this.clAuthSvc.isLoggedIn.subscribe(res => {
      if (res) {
        this.flagLoggedin = true;
      } else {
        this.flagLoggedin = false;
      }
    });
  }

  attachClickEvent(): void {
    window.setTimeout(() => {
      document.addEventListener('click', ($event: any) => {
        LoggingService.info('Attached the click listener');
        if (!$event.target.closest('#elemSettings')) {
          this.closeDialog.emit();
        }
      });
    }, 500);
  }

  detachClickEvent(): void {
    document.removeEventListener('click', () => LoggingService.info('Detached the click listener'));
  }

  ngOnDestroy(): void {
    this.detachClickEvent();
  }

  openReleaseNotes(): void {
    const link = 'https://www.mitel.com/document-center/applications/collaboration/mitel-assistant/all-releases/en/mitel-assistant-release-notes-html';
    window.open(link, '_blank');
  }
}
