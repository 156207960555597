import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as microsoftTeams from '@microsoft/teams-js';
import { telUrl, telUrlAndroid, telUrlMicollab } from '../helpers/common-helper';
import { LoggingService } from './logging.service';
import { TeamsContextService } from './teams-context.service';

@Injectable({
  providedIn: 'root',
})
export class SoftphoneLauncherService {
  window: Window;

  constructor(@Inject(DOCUMENT) private document: Document, private teamsContextService: TeamsContextService) {
    this.window = this.document.defaultView;
  }

  async dial(phoneNumber: string, fromCallhistory: boolean = false): Promise<void> {
    const context = await this.teamsContextService.getContext();
    this.launchSoftphone(context, phoneNumber, fromCallhistory);
  }

  public async launchSoftphone(context: microsoftTeams.app.Context, phoneNumber: string, fromCallhistory: boolean): Promise<void> {
    if (context.app.host.clientType === microsoftTeams.HostClientType.web) {
      try {
        const response = await this.teamsContextService.authenticate({
          url:
            this.window.location.origin +
            '#/softphonelauncher?number=' +
            encodeURIComponent(phoneNumber.replace(/ /g, '')) + (fromCallhistory ? '&fromCallhistory=true' : ''),
          width: 350,
          height: 170,
        });
        LoggingService.info('[SoftphoneLauncherService] Launch Softphone Successful', response);
      } catch (reason) {
        LoggingService.info('[SoftphoneLauncherService] Launch Softphone Failed', reason);
      }
    } else if (context.app.host.clientType === microsoftTeams.HostClientType.ios
                || context.app.host.clientType === microsoftTeams.HostClientType.ipados){
        // this.window.location.href = telUrlMicollab(phoneNumber, fromCallhistory);
        let timeOut;
        let win =  window.open(telUrlMicollab(phoneNumber, fromCallhistory));
        timeOut = window.setTimeout(() => {
          win.close();
          win =  null;
          clearTimeout(timeOut);
          timeOut = null;
        }, 1000);
    } else if (context.app.host.clientType === microsoftTeams.HostClientType.android){
        this.window.location.href = telUrlAndroid(phoneNumber, fromCallhistory);
    } else {
        this.window.open(telUrl(phoneNumber, fromCallhistory));
      }
    }
}
