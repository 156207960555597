import { LogType } from '../enums/log-type';

export class Log {
  logType: LogType;
  message: string;
  data?: any;
  datetime: Date;

  constructor(logType: LogType, message: string, data?: any) {
    this.logType = logType;
    this.message = message;
    if (data) {
      this.data = data;
    }
    this.datetime = new Date();
  }
}
