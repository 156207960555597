import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { AppUtils } from '../common/utils';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[randomColor]',
})
export class RandomColorDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const element = this.elementRef.nativeElement;

    element.style.background = AppUtils.getRandomColor();
  }
}
