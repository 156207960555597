<div [ngSwitch]="loaderType" class="container" [ngClass]="{'container-list': loaderType === 'listLoader' }">
  <ng-template [ngSwitchCase]="'gridLoader'">
    <div class="post" *ngFor="let i of repetition">
      <div class="avatar"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>  
  </ng-template>
  <ng-template [ngSwitchCase]="'listLoader'">
    <div class="post" *ngFor="let i of repetition">
      <div class="line-container">
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="line-container">
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="line-container">
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>  
  </ng-template>
</div>
