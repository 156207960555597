<div class="search-wrapper">
  <div class="inp-wrapper">
    <mi-svg class="search-icon" [iconName]="svgIcons.SEARCH" [pixelSize]="18"></mi-svg>
    <input
    #textbox
    class="contact-list-search-box"
    [ngClass]="{ 'add-contact-search-box': isActionAdd }"
    type="text"
    placeholder="{{ placeHolderText }}"
    [formControl]="contactSearchField"
    (focus)="displaySearchResult()"
    />

    <div *ngIf="isSearchInProgress"
    [ngClass]="{ 'search-in-progress': !isActionAdd, 'search-in-progress-in-add-contact': isActionAdd }">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div *ngIf="showSearchResult"
  class="contact-list-search-results"
  [ngClass]="{ 'contact-list-hide': isContactSelected, 'add-contact-search-result': isActionAdd }"
  >
  <div *ngIf="directoryContacts?.length > 0" class="heading">
    {{ 'contact-search.directory-contact' | translate }}
  </div>

  <ng-container [ngTemplateOutlet]="contactsTemplate" [ngTemplateOutletContext]="{ contacts: directoryContacts }">
  </ng-container>

  <div *ngIf="outlookContacts?.length > 0" class="heading">
    {{ 'contact-search.outlook-contact' | translate }}
  </div>
  <ng-container [ngTemplateOutlet]="contactsTemplate" [ngTemplateOutletContext]="{ contacts: outlookContacts }">
  </ng-container>

  <ng-template #contactsTemplate let-contacts="contacts">
    <div
      *ngFor="let contact of contacts; let i = index"
      class="contact-list-search-result"
      (click)="selectContact(contact)"
    >
      <div
        class="contact-list-search-result-container"
        [ngClass]="{ 'disable-click': isActionAdd && (!contact.hasPhoneNumber || isAddedToSpeedDial(contact)) }"
      >
        
        <div class="avatar-wrapper">
          <img *ngIf="contact.photo" class="avatar-container" [ngStyle]="{ 'background-image': contact.photo }" />
          <div randomColor *ngIf="!contact.photo" class="avatar-container avatar">
            <div class="avatar-text">{{ contact.initials }}</div>
          </div>
          <div presenceStatus [presence]="contact?.presence" [activity]="contact?.activity" [ngClass]="{ 'presence-search': !isActionAdd, 'presence-add': isActionAdd }"></div>
        </div>

        <div tooltip class="contact-display-name">{{ contact.displayName }}</div>
        
        <ng-container *ngIf="isActionAdd && contact.hasPhoneNumber && !isAddedToSpeedDial(contact); else second">
          <button *ngIf="inProgressIndex !== i" (click)="addContact($event, contact, i)" class="add-contact">
            {{ 'speed-dial.actions.add' | translate }}
          </button>
          <div *ngIf="inProgressIndex === i" class="add-in-progress">
            <app-spinner></app-spinner>
          </div>
        </ng-container>
        <ng-template #second>
          <ng-container *ngIf="isActionAdd && isAddedToSpeedDial(contact); else third">
            <div class="added">{{ 'contact-search.search-result.already-added' | translate }}</div>
          </ng-container>
        </ng-template>
        <ng-template #third>
          <ng-container *ngIf="isActionAdd && !contact.hasPhoneNumber">
            <div class="not-available">{{ 'contact-search.search-result.not-available' | translate }}</div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-template>
  </div>
</div>
