import { ContactPresence, ContactActivity } from '../enums/contact-presence';
import { IContact, IPhoneNumber, IContactPhoneNumbers, IContactPhoto, IContactPresence } from '../interfaces/contact';
export class Contact implements Partial<IContact> {
  businessPhones = [] as string[];
  homePhones = [] as string[];
  displayName?: string;
  givenName?: any;
  jobTitle?: any;
  mail?: string;
  mobilePhone?: any;
  officeLocation?: any;
  preferredLanguage?: any;
  surname?: any;
  userPrincipalName?: string;
  id: string;
  photo?: string;
  presence?: ContactPresence;
  activity?: ContactActivity;
  allPhones = [] as IPhoneNumber[];
  selectedPhoneNumber?: string;

  constructor({
    businessPhones,
    homePhones,
    displayName,
    givenName,
    jobTitle,
    mail,
    mobilePhone,
    officeLocation,
    preferredLanguage,
    surname,
    userPrincipalName,
    id,
    photo,
    presence,
    activity
  }: {
    businessPhones?: string[];
    homePhones?: string[];
    displayName?: string;
    givenName?: any;
    jobTitle?: any;
    mail?: string;
    mobilePhone?: any;
    officeLocation?: any;
    preferredLanguage?: any;
    surname?: any;
    userPrincipalName?: string;
    id?: string;
    photo?: string;
    presence?: ContactPresence;
    activity?: ContactActivity;
  }) {
    this.id = id;
    this.businessPhones = businessPhones || [];
    this.homePhones = homePhones || [];
    this.displayName = displayName;
    this.givenName = givenName;
    this.jobTitle = jobTitle;
    this.mail = mail;
    this.mobilePhone = mobilePhone || '';
    this.officeLocation = officeLocation;
    this.preferredLanguage = preferredLanguage;
    this.surname = surname;
    this.userPrincipalName = userPrincipalName;
    this.photo = photo;
    this.presence = presence || ContactPresence.PresenceUnknown;
    this.activity = activity || ContactActivity.PresenceUnknown;

    const businessPhoneNumbers = this.businessPhones
      .filter((p) => !!p)
      .map((p) => ({ type: 'business', number: p } as IPhoneNumber));
    const homePhoneNumbers = this.homePhones
      .filter((p) => !!p)
      .map((p) => ({ type: 'home', number: p } as IPhoneNumber));

    this.allPhones = [...businessPhoneNumbers, ...homePhoneNumbers];
    if (this.mobilePhone) {
      this.allPhones.push({ type: 'mobile', number: this.mobilePhone });
    }

    this.selectPhoneNumber(this.allPhones[0]?.number);
  }

  get initials(): string {
    const names = this.displayName.split(' ');
    return names.length > 1
      ? names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase()
      : names[0].charAt(0).toUpperCase();
  }

  get hasPhoneNumber(): boolean {
    return !!this.allPhones[0]?.number;
  }

  get hasMoreThanOnePhoneNumbers(): boolean {
    return this.allPhones.length > 1;
  }

  get filteredPhoneNumbers(): IPhoneNumber[] {
    return this.allPhones.filter((p) => p.number !== this.selectedPhoneNumber);
  }

  selectPhoneNumber(phoneNumber = ''): void {
    this.selectedPhoneNumber = phoneNumber;
  }

  updatePhones(contactPhones: IContactPhoneNumbers): Contact {
    if (contactPhones.allPhones.length > 0) {
      this.allPhones = contactPhones.allPhones;
      this.selectPhoneNumber(this.allPhones[0].number);
    }
    return this;
  }

  updatePresence(contactPresence: IContactPresence): Contact {
    this.presence = contactPresence.presence || ContactPresence.PresenceUnknown;
    this.activity = contactPresence.activity || ContactActivity.PresenceUnknown;
    return this;
  }

  updatePhoto(contactPhoto: IContactPhoto): Contact {
    this.photo = contactPhoto.photo || '';
    return this;
  }
}

export class StoreContact {
  name: string;
  id: string;
  email: string;
  hasPhone: boolean;
  displayName: string;

  constructor(id: string, name: string, email: string, hasPhone: boolean) {
    this.name =  name;
    this.id = id;
    this.email = email;
    this.hasPhone =  hasPhone;
    this.displayName = name;
  }
}
