import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Contact } from 'src/app/model/contact';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ContactService } from 'src/app/services/contact.service';
import { SoftphoneLauncherService } from 'src/app/services/softphone-launcher.service';
import { Subscription } from 'rxjs';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-contact',
  templateUrl: './speed-dial-contact.component.html',
  styleUrls: ['./speed-dial-contact.component.less'],
})
export class ContactComponent implements OnInit, OnDestroy {
  error = '';
  @Input() contact: Contact;
  @Output() dial: EventEmitter<string> = new EventEmitter();
  showAllPhones = false;
  isDeleteInProgress = false;
  openDeleteDialog = false;

  constructor(
    private contactService: ContactService,
    private softphoneLauncher: SoftphoneLauncherService,
    private elementRef: ElementRef,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  togglePhoneList(): void {
    this.showAllPhones = !this.showAllPhones;
  }

  phoneNumberSelected(): void {
    this.showAllPhones = false;
  }

  dialHandler(contact: Contact): void {
    this.contactService.updateContact(contact);
    this.analytics.record('dialed-from-speeddial');
    this.softphoneLauncher.dial(contact.selectedPhoneNumber);
  }

  async deleteContact(contact: Contact): Promise<void> {
    try {
      this.isDeleteInProgress = true;
      await this.contactService.deleteContact(contact);
    } catch (error) {
      LoggingService.error('[ContactComponent] error in deleteContact' + error.message);
      this.error = error.message;
    } finally {
      this.isDeleteInProgress = false;
      this.openDeleteDialog = false;
    }
  }

  openDialog(): void {
    this.openDeleteDialog = true;
  }

  cancelDeleteContact(): void {
    this.openDeleteDialog = false;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (this.elementRef.nativeElement.contains(event.target)) {
      return;
    }

    this.showAllPhones = false;
  }
}
