import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { isValidPhoneNumber } from 'src/app/helpers/common-helper';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { SoftphoneLauncherService } from 'src/app/services/softphone-launcher.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.less'],
})
export class DialerComponent implements OnInit, OnDestroy {
  number = '';
  window: Window;

  @Output() digits: EventEmitter<string> = new EventEmitter();
  @Input()
  set dialedNumber(num: string) {
    this.number = num;
  }

  constructor(
    private softphoneLauncher: SoftphoneLauncherService,
    private antytics: AnalyticsService) {
    this.number = '';
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }


  get isValidPhoneNumber(): boolean {
    return isValidPhoneNumber(this.number);
  }

  addDigit(digit: string): void {
    this.number += digit;
    this.digits.emit(this.number);
  }

  removeLastDigit(): void {
    this.number = this.number.slice(0, -1);
    this.digits.emit(this.number);
  }

  dialNumber(num?: string): void {
    if (num !== undefined){
      this.softphoneLauncher.dial(num);
    }
    this.antytics.record('dialed-from-dialpad');
    this.softphoneLauncher.dial(this.number);
  }
}
