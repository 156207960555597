import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { forkJoin, Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ContactType } from 'src/app/enums/contact-type';
import { isValidPhoneNumber } from 'src/app/helpers/common-helper';
import { IContactPhoto } from 'src/app/interfaces/contact';
import { Contact } from 'src/app/model/contact';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ContactService } from 'src/app/services/contact.service';
import { SoftphoneLauncherService } from 'src/app/services/softphone-launcher.service';
import { DeviceDetectService } from 'src/app/services/DeviceDetectService.service';
import { LoggingService } from 'src/app/services/logging.service';
import { SVGIcons } from '@mitel-internal/cloudlink-components-catalogue';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.less'],
})
export class ContactListComponent implements OnInit, AfterViewInit, OnDestroy {
  private contactSearchSubscription: Subscription;
  isContactSelected = false;
  directoryContacts = [] as Contact[];
  outlookContacts = [] as Contact[];
  error = '';
  contactSearchField = new FormControl();
  contactPhotos = [] as IContactPhoto[];
  showSearchResult = false;
  inProgressIndex: number;
  isSearchInProgress = false;
  svgIcons = SVGIcons;
  @ViewChild('textbox') textbox: ElementRef;

  @Output() selected: EventEmitter<Contact> = new EventEmitter();
  @Output() digits: EventEmitter<string> = new EventEmitter();

  @Input()
  action: string;

  @Input()
  set searchText(text: string) {
    this.contactSearchField.setValue(text);
  }

  get searchText(): string {
    return this.contactSearchField.value;
  }

  @Input()
  placeHolderText = '';

  get isMobile(): boolean {
    return this.breakpointObserver.isMobile;
  }

  constructor(
    private contactService: ContactService,
    private elementRef: ElementRef,
    private softphoneLauncher: SoftphoneLauncherService,
    private antytics: AnalyticsService,
    private breakpointObserver: DeviceDetectService
  ) {}

  ngOnInit(): void {
    this.contactSearchSubscription = this.contactSearchField.valueChanges
      .pipe(
        tap(() => this.displaySearchResult()),
        map((query) => query.trim()),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((query) => this.getContacts(query))
      )
      .subscribe(() => (this.isContactSelected = false));
  }

  ngAfterViewInit(): void {
    if (!this.isMobile) {// If Mobile then dont focus
      timer(500).subscribe(() => {
        this.textbox.nativeElement.focus();
      });
    }
  }

  ngOnDestroy(): void {
    this.contactSearchSubscription.unsubscribe();
  }

  get isActionAdd(): boolean {
    return this.action === 'add';
  }

  async getContacts(contactName = ''): Promise<void> {
    try {
      this.isSearchInProgress = true;
      this.digits.emit(contactName);
      const allContacts = await this.contactService.getContacts(contactName);
      this.isSearchInProgress = false;

      const directoryContacts = allContacts.find((c) => c.type === ContactType.Directory);
      this.directoryContacts = directoryContacts?.contacts || [];

      const outlookContacts = allContacts.find((c) => c.type === ContactType.Outlook);
      this.outlookContacts = outlookContacts?.contacts || [];

      if (this.isActionAdd) {
        LoggingService.info('[ContactListComponent.getContacts] getting all contacts phone number');
        forkJoin([...this.directoryContacts.map((contact) => this.updateContactPhones(contact))]).subscribe(() => {
          LoggingService.info('[ContactListComponent.getContacts] getting contacts presence');
          forkJoin([...this.directoryContacts.map((contact) => this.updateContactPresence(contact))]).subscribe(() => {
            LoggingService.info('[ContactListComponent.getContacts] getting contacts photos');
            forkJoin([...this.directoryContacts.map((contact) => this.updateContactPhoto(contact))]).subscribe(() => {});
          });
        });
      } else {
          LoggingService.info('[ContactListComponent.getContacts] getting contacts presence');
          forkJoin([...this.directoryContacts.map((contact) => this.updateContactPresence(contact))]).subscribe(() => {
            LoggingService.info('[ContactListComponent.getContacts] getting all contacts photos');
            forkJoin([...this.directoryContacts.map((contact) => this.updateContactPhoto(contact))]).subscribe(() => {});
          });
      }
    } catch (error) {
      this.isSearchInProgress = false;
      LoggingService.error(error.message);
      this.error = error.message;
    }
  }

  async updateContactPhones(contact: Contact): Promise<Contact> {
    const contactPhone = await this.contactService.getContactPhones(contact.id);
    return contact.updatePhones(contactPhone);
  }

  async updateContactPresence(contact: Contact): Promise<Contact> {
    const contactPresence = await this.contactService.getContactPresence(contact.id);
    return contact.updatePresence(contactPresence);
  }

  async updateContactPhoto(contact: Contact): Promise<Contact> {
    const contactPhoto = await this.contactService.getContactPhoto(contact.id);
    return contact.updatePhoto(contactPhoto);
  }

  selectContact(contact: Contact): void {
    if (this.isActionAdd) {
      return;
    }
    this.isContactSelected = true;
    this.contactSearchField.setValue('', { emitEvent: false });
    this.digits.emit('');
    this.selected.emit(contact);
  }

  addContact(e: any, contact: Contact, index: number): void {
    (e.target as HTMLButtonElement).disabled = true;
    this.inProgressIndex = index;
    this.selected.emit(contact);
  }

  isAddedToSpeedDial(contact: Contact): boolean {
    return this.contactService.speedDialContactIds.some((c) => contact.id === c.i);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if ((event.target as HTMLButtonElement).tagName === 'BUTTON') {
      return;
    }
    this.showSearchResult = this.elementRef.nativeElement.contains(event.target) && (event.target && (event.target as any).value);
  }

  displaySearchResult(event: Event = null): void {
    this.showSearchResult = (this.searchText || '').length ? true : false;
  }

  @HostListener('document:keydown.enter', ['$event'])
  enterClicked(): void {
    if (isValidPhoneNumber(this.searchText)) {
      this.dialNumber();
    }
  }

  dialNumber(): void {
    this.antytics.record('dialed-from-dialpad');
    this.softphoneLauncher.dial(this.searchText);
  }

}
