<div [ngClass]="'settings-hover-dialog'" id="elemSettings" #elemSettings>
    <div class="user-info-container">
      <div class="avatar-container">
        <ng-container *ngIf="currentUser?.msUserPhoto; else initials">
          <img [ngStyle]="{'background-image': currentUser?.msUserPhoto}"/>
        </ng-container>
        <ng-template #initials>
          <span class="initials">{{ currentUserInitials }}</span>
        </ng-template
      >
      </div>
      <div class="details-container">
        <p>{{ currentUser?.msUserName }}</p>
        <p>{{ currentUser?.msNameFull }}</p>
      </div>
    </div>
    <br/>
    <div class="hr-line"></div>
    <div class="options-list">
      <button *ngIf = "flagLoggedin" class="list-transparent" mat-raised-button (click)="openReportIssueDialog()">{{ 'login.issue-feedback' | translate }}</button>
      <button *ngIf = "flagLoggedin" class="list-transparent" (click)="logOut()">{{ 'login.logout' | translate }}</button>
      <button *ngIf="!flagLoggedin && isCLEnabled" class="list-transparent" (click)="login()">{{ 'login.sign-in' | translate }}</button>
      <button class="list-transparent" (click)="openReleaseNotes()">
        <span class="release-notes">{{ 'login.release-notes' | translate }}</span>
      </button>
    </div>
    <div class="hr-line"></div>
    <div class="options-list">
      <p class="list-transparent">{{ 'login.app-version-label'| translate }} {{buildVersion}}</p>
    </div>

  </div>