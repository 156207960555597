import { IAuthState } from '../interfaces/auth-state';

export class AuthState implements Partial<IAuthState> {
  username: string;
  accessToken: string;
  expiresOn: number;

  constructor({ username, accessToken, expiresOn }: { username: string; accessToken: string; expiresOn: number }) {
    this.username = username;
    this.accessToken = accessToken;
    this.expiresOn = expiresOn;
  }

  isValid(): boolean {
    return Date.now() < this.expiresOn;
  }
}
