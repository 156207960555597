import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { forkJoin, Subscription, timer } from 'rxjs';
import { ContactUpdateEnum } from 'src/app/enums/contact-update-enum';
import { IContactOrder, ISavedSpeedDialContact } from 'src/app/interfaces/contact';
import { ContactService } from 'src/app/services/contact.service';
import { LoggingService } from '../../../services/logging.service';
import { Contact, StoreContact } from 'src/app/model/contact';
import { ContactActivity, ContactPresence } from 'src/app/enums/contact-presence';
import { CloudAuthenticationService } from 'src/app/services/cloud-authentication.service';

@Component({
  selector: 'app-speed-dial-list',
  templateUrl: './speed-dial-list.component.html',
  styleUrls: ['./speed-dial-list.component.less'],
})
export class SpeedDialListComponent implements OnInit, AfterViewInit, OnDestroy {
  contactsUpdateSubscription: Subscription;
  presenceUpdateSubscription: Subscription;
  cloudLinkloginSubscription: Subscription;
  error = '';
  speedDialContacts = [] as Contact[];
  savedContactIds = [] as ISavedSpeedDialContact[];
  showLoadingEffect = true;
  lsKey = '@msSpeedDialContacts';

  @Output() loaded: EventEmitter<boolean> = new EventEmitter();
  constructor(private contactService: ContactService,  private clAuth: CloudAuthenticationService) {}

  ngOnInit(): void {
    this.getSpeedDialContacts();
    this.validateClLogin();
  }

  ngAfterViewInit(): void {
    this.contactsUpdateSubscription = this.contactService.contactsUpdatedObserver.subscribe((contactUpdate) => {
      if (contactUpdate.operation === ContactUpdateEnum.Add) {
        this.addContactToList(contactUpdate.contactDetails);
      } else {
        this.deleteContactFromList(contactUpdate.contactDetails);
      }
    });
  }

  ngOnDestroy(): void {
    this.contactsUpdateSubscription.unsubscribe();
    if (this.presenceUpdateSubscription) {
      this.presenceUpdateSubscription.unsubscribe();
    }
    if (this.cloudLinkloginSubscription) {
      this.cloudLinkloginSubscription.unsubscribe();
    }
  }

  validateClLogin(): void {
    this.cloudLinkloginSubscription = this.clAuth.isLoggedIn.subscribe(async (res) =>  {
      if (res === true) {
        this.contactService.isCLLoggedIn = true;
        setTimeout(async () => {
          await this.getSpeedDialCLContacts();
        }, 1000);
      } else {
        this.contactService.isCLLoggedIn = false;
      }
    });
  }

  async getSpeedDialCLContacts(): Promise<void>  {
    await this.contactService.getSpeedDialContactIdsListCL().then(async (results) => {
      LoggingService.info('[SpeedDialListComponent.getSpeedDialCLContacts] getting speed dial contact lists CL', results);
      this.savedContactIds = this.contactService.speedDialContactIds;
      const batchSize = 20;
      for (let i = 0; i < results.length; i += batchSize) {
        await this.getContactBatch(results.slice(i, i + batchSize));
      }
      await this.getPersonalContacts();
      this.sortContacts();
      this.saveSpeedDialContactsToLocalStorage();

      LoggingService.info('[ContactListComponent.getContacts] getting contacts presence');
      this.getUpdatedPresence();
      LoggingService.info('[SpeedDialListComponent.getSpeedDialContacts] getting all contacts photo');
      forkJoin([...this.speedDialContacts.map((contact) => this.updateContactPhoto(contact))]).subscribe(
        () => {
          LoggingService.info(
            '[SpeedDialListComponent.getSpeedDialContacts] updating speed dial contacts selected phone number'
          );
          this.updateSelectedPhoneNumber();

          LoggingService.info(
            '[SpeedDialListComponent.getSpeedDialContacts] contactService.speedDialContactIds to reflect list from server'
          );
          this.correctSpeedDialContactList();
        }
      );
    });
  }

  async getSpeedDialContacts(): Promise<void> {
    LoggingService.info('[SpeedDialListComponent.getSpeedDialContacts] getting speed dial contact lists');
    forkJoin([
      this.contactService.getSpeedDialContactIdsListOne(),
      this.contactService.getSpeedDialContactIdsListTwo(),
    ]).subscribe(
      ([speedDialContactListOne, speedDialContactListTwo]) => {
        this.savedContactIds = this.contactService.speedDialContactIds;

        LoggingService.info('[SpeedDialListComponent.getSpeedDialContacts] getting conatcts details in batches');
        forkJoin([
          this.getContactBatch(speedDialContactListOne),
          this.getContactBatch(speedDialContactListTwo),
          this.getPersonalContacts(),
        ]).subscribe(
          () => {
            LoggingService.info('[SpeedDialListComponent.getSpeedDialContacts] sorting contacts');
            this.sortContacts();
            this.saveSpeedDialContactsToLocalStorage();

            LoggingService.info('[ContactListComponent.getContacts] getting contacts presence');
            this.getUpdatedPresence();
            LoggingService.info('[SpeedDialListComponent.getSpeedDialContacts] getting all contacts photo');
            forkJoin([...this.speedDialContacts.map((contact) => this.updateContactPhoto(contact))]).subscribe(
              () => {
                LoggingService.info(
                  '[SpeedDialListComponent.getSpeedDialContacts] updating speed dial contacts selected phone number'
                );
                this.updateSelectedPhoneNumber();

                LoggingService.info(
                  '[SpeedDialListComponent.getSpeedDialContacts] contactService.speedDialContactIds to reflect list from server'
                );
                if (!this.contactService.isCLLoggedIn) {
                  this.correctSpeedDialContactList();
                }
              }
            );

            this.presenceUpdateSubscription = timer(15000, 15000).subscribe(async () => {
              await this.getUpdatedPresence();
            });
          },
          (error) => {
            LoggingService.error(error.message);
            this.error = error.message;
          },
          () => {
            this.showLoadingEffect = false;
            this.loaded.emit();
          }
        );
      },
      (error) => {
        LoggingService.error(error.message);
        this.error = error.message;
      }
    );
  }

  async getUpdatedPresence(): Promise<void> {
    if (this.speedDialContacts.length > 0) {
      const contactIds = [...this.speedDialContacts.values()]
        .map((mscontact: Contact) => mscontact.id)
        .filter((mscontactId) => mscontactId !== null && mscontactId !== undefined && mscontactId !== '');
      if (contactIds && contactIds.length) {
        this.contactService.getPresenceForUsers(contactIds).then((presenceResponse) => {
          if (presenceResponse.size) {
            [...this.speedDialContacts.values()].forEach((contact) => {
              const userPresence = presenceResponse.get(contact?.id);
              if ( userPresence) {
                contact.updatePresence(userPresence);
              }
            });
          }
        });
      }
    }
  }

  private correctSpeedDialContactList(): void {
    // Update contact ids in speed dial list with the contact successfully fetched from server
    // so that correct contact is updated on the server in subsequent speed dial update call
    this.contactService.speedDialContactIds = this.speedDialContacts.map(
      (c) =>
        ({
          i: c.id,
          n: c.selectedPhoneNumber,
        } as ISavedSpeedDialContact)
    );
  }

  async getContactBatch(savedSpeedDialContacts: ISavedSpeedDialContact[]): Promise<void> {
    try {
      const ids = savedSpeedDialContacts.map((c) => c.i);
      const contacts = await this.contactService.getContactBatch(ids);
      this.speedDialContacts = [...this.speedDialContacts, ...contacts];
    } catch (error) {
      LoggingService.error(error.message);
      this.error = error.message;
    }
  }

  async getPersonalContacts(): Promise<void> {
    try {
      const contacts = await this.contactService.getCachedPersonalContacts();
      const savedContacts = contacts.filter((pc) => this.savedContactIds.some((sc) => pc.id === sc.i));
      this.speedDialContacts = [...this.speedDialContacts, ...savedContacts];
      const ids = this.speedDialContacts.map(({ id }) => id);
      this.speedDialContacts = this.speedDialContacts.filter(({ id }, index) => !ids.includes(id, index + 1));
    } catch (error) {
      LoggingService.error(error.message);
      this.error = error.message;
    }
  }

  private updateSelectedPhoneNumber(): void {
    this.speedDialContacts.forEach((speedDialContact) => {
      const savedContact = this.savedContactIds.find((sc) => sc.i === speedDialContact.id);
      const selectedPhoneNumber = speedDialContact.allPhones.find((p) => p.number.substr(-4) === savedContact.n);
      if (selectedPhoneNumber) {
        speedDialContact.selectedPhoneNumber = selectedPhoneNumber.number;
      }
    });
  }
  private sortContacts(): void {
    const contactMap = new Map<string, IContactOrder>();
    this.savedContactIds.forEach((c, index) => contactMap.set(c.i, { id: c.i, order: index + 1 }));
    this.speedDialContacts.sort((a, b) => contactMap.get(a.id).order - contactMap.get(b.id).order);
  }

  async updateContactPhoto(contact: Contact): Promise<Contact> {
    const contactPhoto = await this.contactService.getContactPhoto(contact.id);
    return contact.updatePhoto(contactPhoto);
  }

  async updateContactPresence(contact: Contact): Promise<Contact> {
    const contactPresence = await this.contactService.getContactPresence(contact.id);
    return contact.updatePresence(contactPresence);
  }

  async updateContactPhones(contact: Contact): Promise<Contact> {
    const contactPhone = await this.contactService.getContactPhones(contact.id);
    return contact.updatePhones(contactPhone);
  }

  async addContactToList(contact: Contact): Promise<void> {
    if (this.speedDialContacts.length !== 0) {
      this.speedDialContacts.push(contact);
    }
    this.updateContactPhoto(contact);
    this.updateContactPhones(contact);
    this.saveSpeedDialContactsToLocalStorage();
  }

  deleteContactFromList(contact: Contact): void {
    this.speedDialContacts = this.speedDialContacts.filter((c) => c.id !== contact.id);
    this.saveSpeedDialContactsToLocalStorage();
  }

  saveSpeedDialContactsToLocalStorage(): void {
    const speedDialString = JSON.stringify([
      ...this.speedDialContacts.map(
        (sdContact: Contact) =>
          new StoreContact(sdContact.id, sdContact.displayName, sdContact.mail, sdContact.hasPhoneNumber)
      ),
    ]);
    localStorage.setItem(this.lsKey, speedDialString);
  }
}
