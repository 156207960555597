import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectService {
  private mobileScreen: boolean;
  get isMobile(): boolean {
    return this.mobileScreen;
  }

  get isMidSized(): boolean {
    return this.breakPointObs.isMatched('(max-width : 1099px)');
  }
  constructor( private breakPointObs: BreakpointObserver) {
    this.breakPointObs.observe([Breakpoints.XSmall]).subscribe((responseState: BreakpointState) => {
      this.mobileScreen = responseState?.matches;
    });
  }
}
