import { InjectionToken } from '@angular/core';

export const permissions = [
  'offline_access',
  'User.Read',
  'User.ReadWrite',
  'Contacts.Read',
  'User.Read.All',
  'Directory.Read.All',
  'Chat.Read',
  'Presence.Read',
  'Presence.Read.All',
];
export const MSTEAMS = new InjectionToken('MSTeamsToken');

export const SpeedDial = {
  CLTagId: 'MitelAssistantSpeedDials',
  MaxSpeedDialWithoutCL: 20,
  MaxSpeedDialWithCL: 100,
};

export const pbxType = {
  MiVO5000: 'MiVoice 5000',
  MXONE: 'MX-ONE',
  MiVO400: 'MiVoice Office 400',
  MiVB: 'MiVoice Business',
};
