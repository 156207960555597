export enum ContactPresence {
  Available = 'Available',
  AvailableIdle = 'AvailableIdle',
  Away = 'Away',
  BeRightBack = 'BeRightBack',
  Busy = 'Busy',
  BusyIdle = 'BusyIdle',
  DoNotDisturb = 'DoNotDisturb',
  Offline = 'Offline',
  PresenceUnknown = 'PresenceUnknown'
}

export enum ContactActivity {
  Available = 'Available',
  AvailableIdle = 'AvailableIdle',
  Away = 'Away',
  BeRightBack = 'BeRightBack',
  Busy = 'Busy',
  BusyIdle = 'BusyIdle',
  DoNotDisturb = 'DoNotDisturb',
  Offline = 'Offline',
  PresenceUnknown = 'PresenceUnknown',
  InCall = 'InACall',
  InAConferenceCall = 'InAConferenceCall',
  Inactive = 'Inactive',
  InAMeeting = 'InAMeeting',
  OffWork = 'OffWork',
  OutOfOffice = 'OutOfOffice',
  Presenting = 'Presenting',
  UrgentInterruptionsOnly = 'UrgentInterruptionsOnly'
}
