import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-load-contact',
  templateUrl: './load-contact.component.html',
  styleUrls: ['./load-contact.component.less']
})
export class LoadContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
