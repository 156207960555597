export class ComponentLoginUrlMap {
  private static map = new Map<string, string>([
    ['tab', 'loginhelper'],
    ['conv-member', 'compose-loginhelper'],
  ]);

  static getComponentUrl(loginUrl = ''): string {
    const invertedMap = new Map([...this.map.entries()].map(([key, value]) => [value, key]));
    return invertedMap.get(loginUrl);
  }

  static getLoginUrl(componentUrl = ''): string {
    return this.map.get(componentUrl);
  }
}
