<div class="voicemail-container">
    <div class="voicemail-content">
        <div [ngClass]="voicemailIndicator ? 'voicemail-icon-yes' : 'voicemail-icon-no'"></div>
        <p *ngIf="voicemailIndicator" class="message1">{{ 'call-history.voicemail-available-1' | translate }}</p>
        <p *ngIf="voicemailIndicator" class="message2">{{ 'call-history.voicemail-available-2'| translate }}</p>
        <p *ngIf="!voicemailIndicator" class="message1">{{ 'call-history.voicemail-not-available-1' | translate }}</p>
        <p *ngIf="!voicemailIndicator" class="message2">{{ 'call-history.voicemail-not-available-2' | translate }}</p>
        <button class="voicemail-button" (click)="dialNumber()">
            {{ 'call-history.callVoicemail' | translate }}
        </button>
    </div>    
</div>