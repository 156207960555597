import { Injectable } from '@angular/core';
import { createNotify, Subscription, NotificationsService } from '@mitel/cloudlink-sdk';
import { environment } from '../../environments/environment';
import { LoggingService } from './logging.service';
import { defer, Subject } from 'rxjs';
import { CloudAuthenticationService } from './cloud-authentication.service';
declare const Paho: any;

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  notify: NotificationsService = null;
  callHistoryNotification$: Subject<any> = new Subject();
  voicemailNotification$: Subject<any> = new Subject();
  private subRefreshes = [];
  private retryAttempt = 0;

  constructor(private cloudAuthenticationService: CloudAuthenticationService) { }

  public setupNotify(): void {
    LoggingService.info('[NotifyService.setupNotify]: setting up notification service');
    this.createNotify();
    this.listenToMedia();
    this.subscribeToCallRecords();
    this.subscribeToVoicemails();
  }

  public unsubscribe(): void {
    if (!this.notify) {
      return;
    }
    LoggingService.info('[NotifyService.unsubscribe]: unsubscribing notification service');
    this.notify.unsubscribeDevice();
    this.subRefreshes.forEach(intervel => {
      clearInterval(intervel);
    });
  }

  private createNotify(): void {
    try {
      this.notify = createNotify({
        websocket: {
          deviceId: environment.awsAmplify.appId + performance.now(),
          pahoMqtt: (websocketUrl, clientId) => {
            const client = new Paho.Client(websocketUrl, clientId);
            console.log('[NotifyService.createNotify]:websocketUrl', websocketUrl);
            client.onConnected = () => {
              LoggingService.info('[NotifyService.createNotify]:Socket created and connected');
            };
            return client;
          },
          autoReconnect: true
        }
      });
    } catch (error) {
      LoggingService.error('[NotifyService.createNotify]: Error ', error);
    }
  }

  private subscribeToCallRecords(): void {
    if (!this.notify) {
      return;
    }
    LoggingService.info('[NotifyService.subscribeToCallRecords]:Subscribing to call records');
    const subjectFilter = '2017-09-01/calls/([^/]+/)?records';
    this.notify
      .subscribe({
        transport: 'websocket',
        topic: 'platform-api-media',
        subjectFilter,
        expiryDateTime: this.getExpiryTime()
      })
      .then((subscription: Subscription) => {
        LoggingService.info('[NotifyService.subscribeToCallRecords]: Subscription:', subscription);
        this.refreshSubscription(subscription);
      })
      .catch(error => {
        LoggingService.error('[NotifyService.subscribeToCallRecords]', error);
        if (this.retryAttempt < 3) {
          this.retryAttempt++;
          setTimeout(() => {
              LoggingService.info('[NotifyService.subscribeToCallRecords - retry count]', this.retryAttempt);
              this.setupNotify();
          }, 3000);
        } else {
          LoggingService.error('[NotifyService.subscribeToCallRecords- retry failed and completed]', this.retryAttempt);
        }
      });
  }

  private subscribeToVoicemails(retry = true): void {
    if (!this.notify) {
      return;
    }
    LoggingService.info('[NotifyService.subscribeToVoicemails()]');
    const subjectFilter = 'endpoints/' + this.cloudAuthenticationService.extensionId + '/voicemails';
    this.notify
      .subscribe({
        transport: 'websocket',
        topic: 'platform-api-media',
        subjectFilter,
        expiryDateTime: this.getExpiryTime()
      })
      .then((subscription: Subscription) => {
        LoggingService.info('[NotifyService.subscribeToVoicemails() - Subscription]', subscription);
        this.refreshSubscription(subscription);
      })
      .catch(error => {
        LoggingService.error('[NotifyService.subscribeToVoicemails()]', error);
        if (retry) {
          setTimeout(() => {
              LoggingService.info('[NotifyService.subscribeToVoicemails() - retry]');
              this.subscribeToVoicemails(false);
          }, 3000);
        } else {
          LoggingService.error('[NotifyService.subscribeToVoicemails()- retry failed and completed]');
        }
      });
  }

  private listenToMedia(): void {
    if (!this.notify) {
      return;
    }
    LoggingService.info('[NotifyService.listenToMedia]: method begin');
    this.notify
      .asMessageObservable()
      .subscribe((message: any) => {
        LoggingService.info('[NotifyService.listenToMedia]: got notification:', message);
        if (message.subject.includes('records')) {
          LoggingService.info('[NotifyService.listenToMedia]: got notification for Call history:', message);
          this.callHistoryNotification$.next(message);
        }else if (message.subject.includes('voicemails')) {
          LoggingService.info('[NotifyService.listenToMedia]: got notification for Voicemail:', message);
          this.voicemailNotification$.next(message);
        }
      });
  }

  getExpiryTime(hours = 24): Date {
    // 24 hours
    const oneDayInMS: number = 1000 * 60 * 60 * hours;
    return new Date(new Date().getTime() + oneDayInMS);
  }

  private refreshSubscription(subscription: Subscription, hours = 6): void {
    // 6 hours
    const refreshInterval: number = 1000 * 60 * 60 * hours;
    this.subRefreshes.push(
      setInterval(() => {
        LoggingService.info('[NotifyService.refreshSubscription]: subscription Id:', subscription.subscriptionId);
        this.updateSubscription(subscription);
      }, refreshInterval)
    );
  }

  private updateSubscription(subscription: Subscription): void {
    const expiryTime = 24;
    defer(() =>
      this.notify.updateSubscription({
        subscriptionId: subscription.subscriptionId,
        body: { expiryDateTime: this.getExpiryTime(expiryTime) }
      })
    ).toPromise();
  }
}

