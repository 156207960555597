import { Injectable } from '@angular/core';
import { LightTheme } from '../themes/light-theme';

declare const appLoaded: any;

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public themeProperties = {};

  constructor() {
  }

  public setStyles(theme: any): void {
    // Just default to the light theme for now
    const properties = LightTheme.properties;

    this.themeProperties = properties;

    Object.keys(properties).forEach(property => {
      document.documentElement.style.setProperty(property, properties[property]);
    });
  }
}
