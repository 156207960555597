import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CloudAuthenticationService } from './cloud-authentication.service';
import { LoggingService } from './logging.service';
import { catchError, concatMap, filter, map } from 'rxjs/operators';
import { ICall, ICallResp } from 'src/app/interfaces/call-history';
import { CallHistory } from 'src/app/model/call-history';
import { IUser, IUserResp } from '../interfaces/user';
import { User } from '../model/user';
import { HttpClient } from '@angular/common/http';
import { ContactService } from './contact.service';
import { Contact } from '../model/contact';
import { AppUtils } from '../common/utils';
import { MediaService, AdminService, Config} from '@mitel/cloudlink-sdk';

@Injectable({
  providedIn: 'root',
})
export class CallHistoryService {
  public contacts = [];
  private isClLoggedIn: boolean;
  public lastSyncTime = null;
  get flagClLoggedIn(): boolean {
    return this.isClLoggedIn;
  }
  mediaService: MediaService;
  adminService: AdminService;
  public phoneNoLists = new Set();

  selectedRecord: BehaviorSubject<ICall | null> = new BehaviorSubject(null);

  constructor(
    private clAuth: CloudAuthenticationService,
    private contactService: ContactService,
    private http: HttpClient,
  ) {

    this.mediaService =  new MediaService({url: environment.cloudlink.mediaUrl, http: Config.http});
    this.adminService =  new AdminService({url: environment.cloudlink.adminUrl, http: Config.http});
  }

  public convertNotificaonToCallrecord(notifiItem: ICallResp): any {
    const callItem = new CallHistory(notifiItem);
    return this.getCallHistoryDisplyItem([callItem]);
  }

  public getUserCallRecords(skip: number, top: number, callRecordsMap: Map<string, ICall>): any {
    return this.getCallRecords(skip, top).pipe(
      map((callres: any) =>
        (callres && callres._embedded && (callres._embedded.items as ICallResp[])).map(
          (callresItem: ICallResp) => {
            return new CallHistory(callresItem);
          }
        )
      ),
      concatMap((processedRes: ICall[]) => {
        let callHistoryRecords: ICall[];
        processedRes = processedRes || [];
        callHistoryRecords = this.filterNewCallRecords(processedRes, callRecordsMap);
        return this.getCallHistoryDisplyItem(callHistoryRecords);
      }),
      catchError(async (error) => {
        LoggingService.error('Error in fetching the call records', error);
        await this.verifyAuthError(error);
        // Giving a delay so that a next request will dispatch and update the call history within that time
        return new Promise((resolve) => {
          setTimeout(() => resolve (of([])), 6000);
        });
      })
    );
  }

  private getCallHistoryDisplyItem(callItem: ICall[]): Observable<any> {
    return this.getUserDetails(this.getUniqueUsers(callItem), 'userId').pipe(
      map((users: any) => {
        let userList: IUser[];
        if (users?._embedded?.items) {
          this.contacts = [...users._embedded.items];
          userList = (users._embedded.items as IUserResp[]).map((user: IUserResp) => new User(user));
        } else {
          userList = [];
        }
        return this.mapCallstoUsers(callItem, userList);
      }),
      catchError(async (usrError) => {
        LoggingService.error('[CallHistoryService.getCallHistoryDisplyItem] Error in fetching the user details', usrError);
        await this.verifyAuthError(usrError);
        return of(callItem);
      })
    );
  }

  getUserDetails(data: any, key: string): Observable<any> {
    if (typeof data !== 'string') {
      data = Array.from([...data]).toString() || ''; // comma separated list format
    }
    if (data === '') {
      return of([]); // return a blank array incase there is no user to query for
    }
    return this.getUserDetailsByQuery(data, key);
  }

  filterNewCallRecords(callList1: ICall[], callList2: Map<string, ICall>): any[] {
    const newCallList: ICall[] = [];
    callList1.forEach((call: ICall) => {
      if (!callList2.get(call.callId)) {
        if ( call?.completed === false && call.direction === 'inbound' && (Number(call.createdOn) === Number(call.endTime))) {
        } else {
          newCallList.push(call);
        }
      }
    });
    return [...newCallList];
  }

  public getUniqueUsers(items): Set<any> {
    const uniqueUsers = new Set();
    items.forEach((item) => {
      const userid = item.direction === 'inbound' ? item.fromPrincipalId : item.toPrincipalId;
      if (userid !== undefined && userid.replace(/\s/g, '').length > 0) {
        if (!this.isValidUUID(userid) && !uniqueUsers.has(userid)) {
          this.phoneNoLists.add({userid, direction: item.direction});
        }
        uniqueUsers.add(userid);
      }
    });
    return uniqueUsers;
  }

  isValidUUID(str): boolean {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
  }

  mapCallstoUsers(calls: ICall[], users: IUser[]): any {
    if (calls.length && users.length) {
      users.forEach((usr: IUser) => {
        calls.forEach((call) => {
          if ((call.direction === 'inbound' ? call.fromPrincipalId : call.toPrincipalId) === usr.userId) {
            if (!usr.avatars) {
              const key = 'initials';
              call[key] = (usr.name + 'X').toUpperCase().slice(0, 1);
            } else {
              const key = 'avatars';
              call[key] = usr.avatars;
            }
          }
        });
      });
    }
    if (calls.length) {
      const key = 'initials';
      calls.forEach((call: ICall) => {
        if (!(call.avatars || call.initials)) {
          call[key] = ((call.direction === 'inbound' ? AppUtils.getInitials(call.fromName) : AppUtils.getInitials(call.toName)) + 'X')
            .toUpperCase()
            .slice(0, 2)
            .replace(/(0|1|2|3|4|5|6|7|8|9|\+)/g, 'X');
        }
      });
    }
    return calls;
  }

  public getCallRecords($Skip: number = 0, $Top: number = 40): Observable<any> {
    const options = {headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + this.clAuth.accessToken,
    }};
    const params =  {
          $Skip,
          $Top,
          $Filter: `contains(principal, '${this.clAuth.principalId}')`,
          options
        };
    return from(this.mediaService.getCallRecords(params));
  }

  public updateCallRecord(param: {callId: string, body: {hide: boolean}}): Promise<any> {
    const params = {
      ...param,
      options: { headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + this.clAuth.accessToken,
      }}
    };
    return this.mediaService.updateCallRecord(params);
  }

  public getUserDetailsByQuery(query: string, key: string): Observable<any> {
    const params = {
      items: query,
      itemKeys: key,
      options: {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + this.clAuth.accessToken,
        }
      }
    };
    return from (this.adminService.getUsers(params));
  }

  public getUserInfo(id: string): Observable<any> {
    const params = {
      userId: id,
      options: {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + this.clAuth.accessToken,
        }
      }
    };
    return from(this.adminService.getUser(params));
  }

  async verifyAuthError(error: any): Promise<void> {
    if (error && error.status === 401) {
      try {
        LoggingService.error('[CallHistoryService] Authentication error', error, true);
        const refreshToken = await this.clAuth.refreshToken();
        if ( refreshToken && refreshToken.access_token) {
          this.clAuth.accessToken = refreshToken.access_token;
          this.lastSyncTime = new Date(Date.now() - 15000); // request for another call record immediately as we have token now
        } else {
          window.location.reload(); // incase of any fault just reload
        }
      } catch (err) {
        window.location.reload(); // incase of any fault just reload
      }
    }
  }

  subscribeToClAuth(): Observable<boolean> {
    this.clAuth.isLoggedIn.subscribe((res: boolean) => (this.isClLoggedIn = res));
    LoggingService.info('[CallHistoryService.subscribeToClAuth] CL-logged in:]', this.isClLoggedIn);
    return this.clAuth.isLoggedIn;
  }

  validateCLLogin(): Promise<boolean> {
    return this.clAuth.validateLogin(false);
  }

  async addContactToSpeedDial(contact: Contact): Promise<any> {
    try {
      return await this.contactService.addContact(contact);
    } catch (error) {
      LoggingService.error('[Call Hisotry Service] Error in adding the contact to speed dial.', error);
      return Promise.reject(error);
    }
  }

  async deleteContactFromSpeedDial(contact: Contact): Promise<any> {
    try {
      return await this.contactService.deleteContact(contact);
    } catch (error) {
      LoggingService.error('[Call Hisotry Service] Error in remvoving the contact from speed dial.', error.message);
      return Promise.reject(error);
    }
  }

  public getServiceInfo(): Observable<any> {
    const params = {
      options: {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + this.clAuth.accessToken,
        }
      }
    };
    return from( this.mediaService.getServiceInfo(params));
  }

  public updateSelectedRecord( call: ICall | null): void {
      this.selectedRecord.next(call);
  }

  public getSelectedRecord(): BehaviorSubject<ICall | null> {
    return this.selectedRecord;
  }

  public async getAccountTag(accountId: string, tagId: string): Promise<any> {
    const params = {
      accountId,
      tagId,
      options: {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + this.clAuth.accessToken,
        }
      }
    };
    return this.adminService.getAccountTag(params);
  }

  public getVmPilotNumber(userId, voicemailNumber): Promise<any> {
    const params = {
      userId,
      voicemailNumber,
      options: { headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + this.clAuth.accessToken,
      }}
    };
    return this.mediaService.getUserVoicemailBox(params);
  }

  public async getCountryCode(accountId): Promise<any> {
    const params = {
      accountId,
      options: {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + this.clAuth.accessToken,
        }
      }
    };
    return this.adminService.getAccount(params);
  }
}
