import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as MicrosoftGraphClient from '@microsoft/microsoft-graph-client';
import { DOCUMENT } from '@angular/common';
import { ContactService } from 'src/app/services/contact.service';
import { Contact } from 'src/app/model/contact';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DeviceDetectService } from 'src/app/services/DeviceDetectService.service';
import { Observable, Subscription } from 'rxjs';
import { CloudAuthenticationService } from 'src/app/services/cloud-authentication.service';
import { ThemeService } from '../../services/theme.service';
import { CallHistoryComponent } from '../call-history/call-history.component';
import { timer } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IssueReportComponent } from '../issue-report/issue-report.component';
import { dashboardTabs } from 'src/app/enums/tabNames';
import { CallHistoryService } from 'src/app/services/call-history.service';
import { LoggingService } from 'src/app/services/logging.service';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { EulaComponent } from '../eula/eula.component';
import { EulaService } from 'src/app/services/eula.service';
const LSHIDEPOPUP = '@mitel/lsHidePopup';
import { IDashboardMenu, ISubHeaders } from '../../interfaces/menuItems';

@Component({
  selector: 'app-dialer-container',
  templateUrl: './dialer-container.component.html',
  styleUrls: ['./dialer-container.component.less'],
})
export class DialerContainerComponent implements OnInit {
  msGraphClient: MicrosoftGraphClient.Client;
  contacts = [] as Contact[];
  speedDialContactIds = [] as string[];
  showAddSpeedDialComponent = false;
  searchText = '';
  searchPlaceHolderText = '';
  error = '';
  window: Window;
  showAddressBookMsg = false;
  selectedContact: Contact;
  showContactInfoDialog = false;
  isClLoggedIn = null;
  public showSpeedDial = false;
  breakPointObs: Subscription;
  isCallHistoryEnabled: boolean;
  isCLEnabled = false;
  isVoicemailWaitingIndicator = false;
  isCallRecordsClicked = false;
  @ViewChild('callHistory') callHistoryCmp: CallHistoryComponent;
  @ViewChild('elemSettings') settingsWin: UserProfileComponent;
  @ViewChild('eulaPageMobile') eulaDialog: TemplateRef<any>;
  @ViewChild('checkboxHidePopup') hideCheckbox: ElementRef;
  @ViewChild('mainContainer') container: HTMLElement;
  currentUser: any = {};
  tabNames: any[] = [];
  currentSelectedTab;
  flagHidePopup: boolean;
  showSettings = false;
  showReportIssue = false;
  timerSubscription: Subscription;
  pbxIntegrationPresent: boolean;
  public eulaAccepted = true;
  msUsers: any;
  isEditMode = false;
  headerOptions: IDashboardMenu[] = [
    {
      name: this.tabNames[2],
      value: dashboardTabs.DialPad,
      view: [], // removing the views(desktop and mobile) not the tab, if any new changes needed in future
      iconPath: 'assets/icons/dialpad.svg',
      iconPathActive: 'assets/icons/dialpad-active.svg',
      order: 1,
      isClRequired: [true, false],
      hideAtSmallScreen: 'none'
    },
    {
      name: this.tabNames[0],
      value: dashboardTabs.SpeedDials,
      view: ['desktop', 'mobile'],
      iconPath: 'assets/icons/speedDials.svg',
      iconPathActive: 'assets/icons/speedDials-active.svg',
      order: 3,
      isClRequired: [true, false],
      hideAtSmallScreen: true
    },
    {
      name: this.tabNames[1],
      value: dashboardTabs.CallHistory,
      view: ['desktop', 'mobile', 'clLink'],
      iconPath: 'assets/icons/history.svg',
      iconPathActive: 'assets/icons/history-active.svg',
      order: 2,
      isClRequired: [true],
      hideAtSmallScreen: 'none'
    },
  ];
  eulaRes = null;
  dialogRef: MatDialogRef<any>;
  subHeaders: ISubHeaders[];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private contactService: ContactService,
    private translate: TranslateService,
    private analytics: AnalyticsService,
    private clAuth: CloudAuthenticationService,
    private breakpointObserver: DeviceDetectService,
    private themeSvc: ThemeService,
    private dialog: MatDialog,
    private callHistoryService: CallHistoryService,
    private eulaService: EulaService
  ) {
    this.window = this.document.defaultView;
  }

  async ngOnInit(): Promise<void> {
    LoggingService.info('[dialer-component.ngOnInit]');
    this.flagHidePopup = localStorage.getItem(LSHIDEPOPUP) === 'true';
    this.getTranslations();
    this.themeSvc.setStyles('oneapp');
    this.subscribeLDFlags();
    this.validateClLogin();
    this.contactService.getPersonalContacts();
  }

  subscribeLDFlags(): void {
    this.isCLEnabled = true;
    this.isCallHistoryEnabled = true;
    this.isVoicemailWaitingIndicator = false;
    this.selectDefaultView();
  }

  private selectDefaultView(): void {
    this.updateHeaders();
    if (this.isMobileView) {
      this.selectedTab('speedDials');
      this.isCLEnabled = this.isCLEnabled;
    } else {
      this.selectedTab(this.subHeaders[1]?.value || null);
    }
  }

  public updateHeaders(): void {
    if (this.isClLoggedIn) {
      if (this.isCallHistoryEnabled && this.pbxIntegrationPresent) {
         this.subHeaders = this.headerOptions;
      } else {
         this.subHeaders = this.headerOptions.slice(0, 2);
      }
    } else {
       this.subHeaders = this.headerOptions.slice(0, 2);
    }
  }

  get isMobileView(): boolean {
    return this.breakpointObserver.isMobile;
  }

  get isMidSizedScreen(): boolean {
   return this.breakpointObserver.isMidSized;
  }

  openReportIssueDialog(): void {
    this.showSettingsDialog(); // close the settings popup
    if (this.isMobileView) {
      this.showReportIssue = true;
      this.settingsWin.setStyles(true, true);
      return;
    }
    this.dialog.open(IssueReportComponent, {
      autoFocus: false,
      backdropClass: 'backdropBackground',
      disableClose: true,
    });
  }

  getTranslations(): void {
    this.translate.get('contact-search.placeholder').subscribe((text: string) => {
      this.searchPlaceHolderText = text;
    });
    this.translate.get('title.tabs').subscribe((translateTabs) => {
      this.tabNames = translateTabs.split(', ');
      this.headerOptions[0].name =  this.tabNames[2];
      this.headerOptions[1].name =  this.tabNames[0];
      this.headerOptions[2].name =  this.tabNames[1];
    });
  }

  get allowAddingSpeedDialContact(): boolean {
    return this.contactService.getSpeedDialsLimit();
  }

  get isSpeedDialListEmpty(): boolean {
    return this.contactService.speedDialContactIds.length === 0;
  }

  get currentUserName(): string {
    return this.currentUser;
  }

  set currentUserName(user: string) {
    this.currentUser = user;
  }

  setSpeedDialLoading(): void {
    this.showAddressBookMsg = true;
  }

  showAddSpeedDial(): void {
    this.showAddSpeedDialComponent = true;
  }

  handleAddCancelEvent(event: Contact | string | null): void {
    this.showAddSpeedDialComponent = false;
  }

  updateSearchText(text: string): void {
    this.searchText = text;
  }

  dialHandler(contact: Contact): void {
    this.selectedContact = contact;
    this.showContactInfoDialog = true;
  }

  closeContactInfoDialog(): void {
    this.analytics.record('dialed-from-contact-search');
    this.showContactInfoDialog = false;
  }

  setIsCloudLink(isTrue): void {
    if (isTrue) {
      localStorage.setItem('isCLEnabled', isTrue);
    } else {
      localStorage.removeItem('isCLEnabled');
    }
  }

  validateClLogin(): void {
    this.clAuth.isLoggedIn.subscribe(async (res) => {
      // status is pushed from the service
      this.isClLoggedIn = res;
      this.getLoggedInUserInfo();
      if (res) {
        this.validateEULA();
        this.getServiceInfo();
      }
      this.selectDefaultView();
      Promise.resolve(null);
    });
    this.clAuth.validateLogin(false); // Don't prompt the login widow
  }

  public logOut(reload = false): void {
    this.analytics.record('logout-from-mitel');
    this.showSettingsDialog();
    this.clAuth.logout();
    if (reload) {
      LoggingService.info('[DialerContainerComponent.getServiceInfo] Reloading application...');
      this.window.location.reload();
    }
  }

  public selectedTab(tabName: string): void {
    if (tabName === dashboardTabs.CallHistory && !this.clAuth.principalId) {
      return;
    }
    this.currentSelectedTab = tabName;
    if (tabName === dashboardTabs.CallHistory) {
      this.analytics.record('clicked-call-history');
    }
    this.showSettings = false;
    this.showReportIssue = false;
  }

  public loginClLink(): void {
    this.analytics.record('login-with-mitel');
    if (this.showSettings) {
      // If the popup is open then close it
      this.showSettingsDialog();
    }
    this.clAuth.cloudLinklogin();
  }


  public hideLoginPop(hidePersistent: boolean = false): void {
    if ( hidePersistent) {
      localStorage.setItem(LSHIDEPOPUP, '' + !this.hideCheckbox.nativeElement.checked);
      this.hideCheckbox.nativeElement.checked = !this.hideCheckbox.nativeElement.checked;
      return;
    }
    this.flagHidePopup = true;
  }

  public showSettingsDialog(counter = 0): void {
    if (counter > 50) {
      // Exit if repeatedly getting called and beyond limit
      return;
    }
    this.showSettings = !this.showSettings;
    if (this.showSettings) {
      timer(10).subscribe(() => {
        this.getMSUserInfo(); // get the AD username and name
        if (this.settingsWin && !this.isEmptyCurrentUser()) {
          this.settingsWin.setStyles(true);
        } else {
          this.showSettings = !this.showSettings;
          this.showSettingsDialog(counter++);
        }
      });
    }
  }

  public async getLoggedInUserInfo(): Promise<void> {
    const userInfo = localStorage.getItem('@mitel/customer/info');
    if (userInfo) {
      this.currentUser = JSON.parse(atob(userInfo));
      this.msUsers = await this.contactService.getContactsByEmail(this.currentUser.email);
    }
  }

  public getMSUserInfo(): void {
    const msUserName = localStorage.getItem('msUsername') || '';
    const msNameFull = localStorage.getItem('msName') || '';
    const msUserId = localStorage.getItem('mslocalAccountId') || '';
    this.currentUser = { ...this.currentUser, msUserName, msNameFull}; // set whatever info we have and wait for the photo
    this.contactService.getContactPhoto(msUserId).then((res) => {
      const msUserPhoto = res.photo || null;
      this.currentUser = {...this.currentUser, msUserPhoto};
    });
  }

  public isEmptyCurrentUser(): boolean {
    return Object.keys(this.currentUser).length > 0 ? false : true;
  }

  public getServiceInfo(): void {
    this.callHistoryService.getServiceInfo().subscribe((res) => {
      LoggingService.info('[DialerContainerComponent.getServiceInfo] fetched service info');
      this.pbxIntegrationPresent = res.deskphoneSupport;
      LoggingService.info(`PBX integration is enabled: ${this.pbxIntegrationPresent};`);
      this.selectDefaultView();
      LoggingService.info(`PBX integartion is enabled: ${this.pbxIntegrationPresent};`);
    });
  }

  public getUserName(): string {
    if (this.msUsers.length) {
      return this.msUsers[0].contacts[0].displayName;
    } else {
      return this.currentUser?.name;
    }
  }

  openEULADialog(): void {
    if ( !this.dialogRef) {
       this.dialogRef = this.dialog.open(EulaComponent, {
        autoFocus: false,
        backdropClass: 'backdropBackground',
        disableClose: true
        });
       this.dialogRef.afterClosed().subscribe(() => {
          this.dialogRef = null;
        });
       this.dialogRef.componentInstance.matDialogId = this.dialogRef.id;
    }
  }

  validateEULA(): void {
    this.eulaService.validateEULA();
    timer(1500).subscribe(() => {
      LoggingService.info('[DialerContainerComponent]: Subscribing to EULA flag');
      this.evalEulaFromService().subscribe((eulaRes) => {
        LoggingService.info('[DialerContainerComponent]: Eula Flag Updated', eulaRes);
        this.eulaRes = eulaRes;
        timer(1000).subscribe(() => {
          if ( this.eulaRes === false) {
            if ( !this.isMobileView) {
              this.openEULADialog();
            }
          }
        });
      });
    });
  }

  evalEulaFromService(): Observable<any>{
   return this.eulaService.eulaAccepted as Observable<any>;
  }

}
