<div class="outer-container">
  <div class="inner-container">
    <ng-container *ngIf="!signingIn && !signInFail">
      <div class="header">{{ 'login.permission' | translate }}</div>
      <div class="message">{{ 'login.permission-message' | translate }}</div>
      <button class="login-button" (click)="getMyDetails()">{{ 'login.login-button' | translate }}</button>
    </ng-container>
    <ng-container *ngIf="signingIn && !signInFail">
      <div class="signing-in">{{ 'login.signing-in' | translate }}</div>
    </ng-container>
    <ng-container *ngIf="signInFail">
      <div class="sign-in-unsuccess">{{ 'login.sign-in-fail' | translate }}</div>
      <button class="try-again-button" (click)="tryAgain()">{{ 'login.try-again' | translate }}</button>
    </ng-container>
  </div>
</div>
