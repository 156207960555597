<div *ngIf="contact" class="container">
  
  <div class="avatar-wrapper">
    <div *ngIf="contact.photo" class="avatar-container" [ngStyle]="{ 'background-image': contact.photo }"></div>
    <div randomColor *ngIf="!contact.photo" class="avatar-container avatar">
      <div class="avatar-text">{{ contact.initials }}</div>
    </div>
    <div presenceStatus [presence]="contact?.presence" [activity]="contact?.activity" class="presence" [tooltip]="contact?.availability"></div>
  </div>

  <div class="contact-info-wrapper">
    <div tooltip class="speed-dial-name">{{ contact.displayName }}</div>
    <div class="num-dropdown-container">
      <div tooltip class="speed-dial-number">
        {{ contact.selectedPhoneNumber }}
      </div>
      <div *ngIf="showAllPhones" class="speed-dial-dropdown">
        <app-phone-number-list [contact]="contact" (selected)="phoneNumberSelected()"></app-phone-number-list>
      </div>
      <div *ngIf="contact.hasMoreThanOnePhoneNumbers" class="speed-dial-dropdown-icon" (click)="togglePhoneList()"></div>
    </div>
  </div>
  <div class="call-delete-container">
    <div class="vertical-line" ></div>
    <div class="horizontal-line"></div>
    <div
      [tooltip]="'speed-dial.actions.delete' | translate"
      *ngIf="!isDeleteInProgress"
      class="speed-dial-delete"
      (click)="openDialog()"
    ></div>
    <div
      [tooltip]="'speed-dial.actions.call' | translate"
      *ngIf="contact.hasPhoneNumber"
      class="speed-dial-call"
      (click)="dialHandler(contact)"
    ></div>
  </div>
  <ng-container *ngIf="openDeleteDialog">
    <div class="delete-dialog-background">
      <div class="delete-dialog">
        <div class="delete-dialog-header">{{ 'speed-dial.delete.dialog-title' | translate }}</div>
        <div class="delete-dialog-msg">
          {{ 'speed-dial.delete.dialog-text' | translate
          }}<span class="display-name">&nbsp;{{ contact.displayName }}?</span>
        </div>
        <div class="delete-actions">
          <div *ngIf="isDeleteInProgress" class="delete-in-progress">
            <app-spinner></app-spinner>
          </div>
          <button class="delete-button" (click)="deleteContact(contact)" [disabled]="isDeleteInProgress">
            {{ 'speed-dial.actions.delete' | translate }}
          </button>
          <button class="cancel-button" (click)="cancelDeleteContact()" [disabled]="isDeleteInProgress">
            {{ 'speed-dial.actions.cancel' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
