import { Component, OnInit, Input } from '@angular/core';
import { LoggingService } from 'src/app/services/logging.service';
import { SoftphoneLauncherService } from 'src/app/services/softphone-launcher.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-voicemail',
  templateUrl: './voicemail.component.html',
  styleUrls: ['./voicemail.component.less']
})
export class VoicemailComponent implements OnInit {
  @Input() vmPilotNumber: any;
  @Input() voicemailIndicator: boolean;

  constructor(
    private softphoneLauncher: SoftphoneLauncherService,
    private anlytics: AnalyticsService
  ) { }

  ngOnInit(): void {}

  dialNumber(): void {
    if (this.vmPilotNumber) {
      LoggingService.info('[voicemail.component.dialnumber()]', this.vmPilotNumber);
      this.anlytics.record('dialed-from-voicemail');
      this.softphoneLauncher.dial(this.vmPilotNumber.pilotNumber);
    } else {
      LoggingService.error('[voicemail.component.dialNumber()] call failed, vmPilotNumber doesnt exist');
    }
  }
}
