import { Component, Inject, OnInit } from '@angular/core';
import * as microsoftTeams from '@microsoft/teams-js';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { MSTEAMS, permissions  } from 'src/app/common/constants';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-teams-auth-popup',
  templateUrl: './auth-popup.component.html',
  styleUrls: ['./auth-popup.component.less'],
})
export class AuthPopupComponent implements OnInit {
  window: Window;
  error = '';

  constructor(
    public authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MSTEAMS) public msTeams: typeof microsoftTeams,
  ) {
    this.window = this.document.defaultView;
  }

  async ngOnInit(): Promise<void> {
    try {
      if ( !this.msTeams.app.isInitialized()) {
        await this.msTeams.app.initialize();
      }
      const context =  await this.msTeams.app.getContext();
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (!userAgent.includes('teams') && context?.user?.userPrincipalName !== localStorage.getItem('msUsername')){
        localStorage.removeItem('msUsername');
      }
      if (context) {
        await this.login(context?.user?.userPrincipalName);
      } else {
        this.msTeams.authentication.notifyFailure('MS Teams Context not available');
        return Promise.resolve(null);
      }
    } catch (err) {
      LoggingService.error('Something went wrong while initializing', err);
      this.msTeams.authentication.notifyFailure('MS Teams Context not available');
    }
  }

  async login(loginHint = ''): Promise<void> {
    const isLoggedIn = await this.authService.isLoggedIn();
    if (!isLoggedIn) {
      try {
        this.authService.login(permissions, loginHint);
      } catch (error) {
        this.error = error.message;
      }
    } else {
      try {
        const { username, accessToken, expiresOn } = await this.authService.getAccessTokenWithExpiry(permissions);
        if (accessToken) {
          const response = JSON.stringify({ username, accessToken, expiresOn, localStorageFromPopupWindow: {...localStorage} });
          LoggingService.info('[AuthPopupComponent.login]: logged in successfully');
          this.msTeams.authentication.notifySuccess(response);
        } else {
          LoggingService.error('[AuthPopupComponent.login]: failed to login - null token received');
          this.msTeams.authentication.notifyFailure('Unexpected failure - null token received');
        }
      } catch (error) {
        this.error = error.message;
        LoggingService.error('[AuthPopupComponent.login]: failed to login ' + this.error);
        this.msTeams.authentication.notifyFailure(error.message);
      }
    }
  }
}
