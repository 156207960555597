import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from 'src/app/model/contact';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ContactService } from 'src/app/services/contact.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-speed-dial',
  templateUrl: './add-speed-dial.component.html',
  styleUrls: ['./add-speed-dial.component.less'],
})
export class AddSpeedDialComponent implements OnInit, OnDestroy {
  error = '';
  window: Window;

  searchPlaceHolderText = '';
  @Output() add: EventEmitter<Contact | string | null> = new EventEmitter();

  constructor(
    private contactService: ContactService,
    private translate: TranslateService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.translate.get('speed-dial.add.placeholder').subscribe((text: string) => {
      this.searchPlaceHolderText = text;
    });
  }

  ngOnDestroy(): void {
  }

  async addContact(contact: Contact): Promise<void> {
    try {
      await this.contactService.addContact(contact);
      this.analytics.record('contact-added-to-speeddials');
      this.raiseAddEvent(contact);
    } catch (error) {
      this.raiseAddEvent(null);
      this.error = error.message;
    }
  }

  private raiseAddEvent(contact: Contact): void {
    this.add.emit(contact);
  }

  raiseCancelAddEvent(): void {
    this.add.emit('');
  }
}
