import { colors } from './colors';

export const LightTheme = {
  properties: {
    // Note some of these MOWA ONLY ones may still be in OneApp, but by release they should not exist in the OneApp view
    '--mi-primary-hover': colors.primaryHover, // MOWA ONLY / replaced primaryHover
    '--mi-primary-button-disabled': '0,115,208', // MOWA ONLY / Must be RGB
    '--mi-secondary-button-gradient-top': colors.oneAppWhiteBg, // MOWA ONLY / replaced secondaryButtonGradientTop
    '--mi-secondary-button-gradient-bottom': colors.oneAppWhite, // MOWA ONLY / replaced secondaryButtonGradientBottom
    '--mi-primary-button-color': colors.oneAppWhite, // MOWA ONLY / replaced white
    '--mi-secondary-button-gradient-bottom-alternate': colors.oneAppWhite, // MOWA ONLY / replaced white
    '--mi-secondary-button-border-hover': colors.oneAppInk600, // MOWA ONLY / replaced secondaryButtonBorderHover
    '--mi-secondary-button-border-active': colors.oneAppGreyBg, // MOWA ONLY / replaced lightGrey
    '--mi-secondary-button-color': colors.oneAppInk800, // MOWA ONLY / replaced darkGrey
    '--mi-delete-button-gradient-top': colors.deleteButtonGradientTop, // MOWA ONLY
    '--mi-delete-button-gradient-bottom': colors.deleteButtonGradientBottom, // MOWA ONLY
    '--mi-call-status-background': colors.callStatusBackground, // MOWA ONLY
    '--mi-call-status-hover': colors.callStatusHover, // MOWA ONLY
    '--mi-call-status-muted': colors.callStatusMuted, // MOWA ONLY
    '--mi-call-status-holding': colors.callStatusHolding, // MOWA ONLY
    '--mi-call-screen-hangup-gradient-top': colors.callScreenHangupGradientTop, // MOWA ONLY
    '--mi-call-screen-hangup-gradient-bottom': colors.callScreenHangupGradientBottom, // MOWA ONLY
    '--mi-call-screen-call-gradient-top': colors.callScreenCallGradientTop, // MOWA ONLY
    '--mi-call-screen-call-gradient-bottom': colors.callScreenCallGradientBottom, // MOWA ONLY

    // These are all used in OneApp
    '--mi-green': colors.oneAppGreen, // replaced accessibleGreem
    '--mi-medium-grey': colors.oneAppGreyHdr, // replaced mediumGrey
    '--mi-dark-grey': colors.oneAppInk800, // replaced darkGrey
    '--mi-light-grey': colors.oneAppGreyBg, // replaced lightGrey
    '--mi-dark-blue': colors.oneAppDarkBlue, // replaced mitelDarkBlue
    '--mi-white': colors.oneAppWhite, // replaced white
    '--mi-black': colors.oneAppBlack, // replaced black
    '--mi-tangerine': colors.tangerine,
    '--mi-orange': colors.oneAppOrange, // replaced orange
    '--mi-red': colors.oneAppRed600, // replaced accessibleRed
    '--mi-primary': colors.oneAppBlue500, // replaced primary
    '--mi-primary-color': colors.oneAppWhite, // replaced white
    '--mi-app-background': colors.oneAppGreyBg, // replaced lightGrey
    '--mi-office-background': colors.oneAppWhite, // replaced white
    '--mi-default-color': colors.oneAppInk900, // replaced almostBlack
    '--mi-default-sub-color': colors.oneAppGreyHdr, // replaced mediumGrey
    '--mi-default-border': colors.oneAppGreyBg, // replaced lightGrey
    '--mi-error': colors.oneAppRed600, // replaced warning
    '--mi-error-color': colors.oneAppWhite, // replaced white
    '--mi-warning': colors.oneAppYellow, // replaced caution
    '--mi-warning-color': colors.oneAppInk800, // replaced darkGrey
    '--mi-warning-dialog-logo':
      `url(\"data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='%23464b51' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E\")`,
    '--mi-success': colors.oneAppGreen, // replaced success
    '--mi-primary-button-gradient-top': colors.primaryButtonGradientTop,
    '--mi-primary-button-gradient-bottom': colors.oneAppBlue500, // replaced primaryButtonGradientBottom
    '--mi-delete-button-color': colors.oneAppWhite, // replaced white
    '--mi-one-delete-icon-color': colors.oneAppRed600, // replaced accessibleRed
    '--mi-placeholder-color': colors.oneAppGreyHdr, // replaced mediumGrey
    '--mi-list-border': colors.oneAppWhiteAlt, // replaced listBorder
    '--mi-list-hover-color': colors.oneAppWhiteBg, // replaced basicallyWhite
    '--mi-list-active-color': colors.tableHover,
    '--mi-hover-shadow': colors.mitelDarkBlue,
    '--mi-location': colors.oneAppInk600, // replaced mediumDarkGrey
    '--mi-header': colors.oneAppWhiteBg, // replaced basicallyWhite
    '--mi-ghost': colors.oneAppWhiteAlt, // replaced superLightGrey
    '--mi-ghost-dark': colors.oneAppGreyBg, // replaced lightGrey
    '--mi-icon': colors.oneAppInk700, // replaced conversationIcon
    '--mi-text': colors.oneAppInk700,
    // '--mi-toggle-color': colors.toggleColor, // replaced by following two
    '--mi-toggle-color-gradient-start': colors.toggleColorGradientStart,
    '--mi-toggle-color-gradient-end': colors.toggleColorGradientEnd,
    '--mi-cc-widget-background': colors.oneAppGrey, // replaced ccWidgetBackground
    '--mi-light-widget-gradient-start': colors.lightWidgetBorderGradientStart,
    '--mi-light-widget-gradient-end': colors.lightWidgetBorderGradientEnd,
    '--mi-dark-widget-gradient-start': colors.darkWidgetBorderGradientStart,
    '--mi-dark-widget-gradient-end': colors.oneAppWhite, // replaced darkWidgetBorderGradientEnd
    '--mi-home-background': colors.oneAppWhiteAlt, // replaced homeBackground
    '--mi-widget-title': colors.oneAppInk600, // replaced widgetTitle
    '--mi-input-disabled': colors.oneAppWhiteBg, // replaced inputDisabled
    '--mi-one-app-hover': colors.oneAppHover,
    '--mi-one-app-chat-header-pill-button': colors.oneAppPillButton,
    '--mi-tab-bar-focus': colors.tabBarFocus,
    '--mi-one-app-status-text': colors.oneAppInk600, // replaced oneAppStatusText
    '--mi-call-screen-background': colors.oneAppWhiteAlt, // replaced callScreenBackground
    '--mi-one-app-callscreen-bg': colors.oneAppWhiteAlt, // replaced homeBackground
    '--mi-one-app-callheader-bg': colors.oneAppBlue500, // replaced primary
    '--mi-one-app-sideaction': colors.oneAppGrey // replaced oneAppSideAction
  }
};
