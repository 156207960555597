import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { permissions } from 'src/app/common/constants';
import { telUrl } from 'src/app/helpers/common-helper';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.less'],
})
export class WebComponent implements OnInit {
  window: Window;
  contacts = [];
  speedDialContacts = [];
  error = '';
  contactSearchField = new FormControl();

  constructor(public authService: AuthService, @Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  async ngOnInit(): Promise<void> {
    if (!(await this.authService.isLoggedIn())) {
      // Will redirect the browser and not return; will redirect back if successful
      try {
        this.authService.login(permissions);
      } catch (error) {
        this.error = error.message;
      }
    }
  }

  dialHandler(phoneNumber): void {
    LoggingService.info('[WebComponent] dialHandler called with number ', phoneNumber);
    this.window.location.href = telUrl(phoneNumber);
  }
}
