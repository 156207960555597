import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { Contact } from 'src/app/model/contact';
import { ContactService } from 'src/app/services/contact.service';
import { LoggingService } from 'src/app/services/logging.service';
import { SoftphoneLauncherService } from 'src/app/services/softphone-launcher.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.less'],
})
export class ContactDetailsComponent implements OnInit, OnDestroy {
  error = '';
  showAllPhones = false;
  @Input() contact: Contact;
  timerContactUpdate: Subscription;

  @Output() dialed: EventEmitter<void> = new EventEmitter();

  constructor(
    private contactService: ContactService,
    private softphoneLauncher: SoftphoneLauncherService
    ) {}

  async ngOnInit(): Promise<void> {
    await this.updateContactPhones();
    this.timerContactUpdate = interval(3000).subscribe(() => this.updateContactPresence(this.contact));
  }

  ngOnDestroy(): void {
    this.timerContactUpdate.unsubscribe();
  }

  togglePhoneList(): void {
    this.showAllPhones = !this.showAllPhones;
  }

  dialHandler(phoneNumber = ''): void {
    this.dialed.emit();
    this.softphoneLauncher.dial(phoneNumber);
  }

  phoneNumberSelected(): void {
    this.showAllPhones = false;
  }

  private async updateContactPhones(): Promise<Contact> {
    try {
      const contact = await this.contactService.getContact(this.contact.id);
      const contactPhone = {id: contact.id, allPhones: contact.allPhones};
      return this.contact.updatePhones(contactPhone);
    } catch (err) {
      LoggingService.error(`[ContactDetailsComponent.updateContactPhones]: error: ${err.body}`);
      return null;
    }
  }

  async updateContactPresence(contact: Contact): Promise<Contact> {
    const contactPresence = await this.contactService.getContactPresence(contact.id);
    return contact.updatePresence(contactPresence);
  }
}
