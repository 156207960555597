import { Component, OnInit, OnDestroy } from '@angular/core';
import { CloudAuthenticationService } from 'src/app/services/cloud-authentication.service';
import { EulaService } from 'src/app/services/eula.service';
import { LoggingService } from 'src/app/services/logging.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectService } from 'src/app/services/DeviceDetectService.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.less']
})
export class EulaComponent implements OnInit, OnDestroy {

  isTCClicked = false;
  tcWindowId: WindowProxy;
  matDialogId: any;
  isMobile = false;
  isSubmitted = false;
  subscription: Subscription | null = null;

  constructor(
    private clAuth: CloudAuthenticationService,
    private eulaService: EulaService,
    private matDialog: MatDialog,
    private deviceDetectService: DeviceDetectService
    ) { }

  ngOnInit(): void {}

  closeEulaDialog(): void {
    this.subscription = this.eulaService.eulaAccepted.subscribe((res) => {
      if (res === false) { // If the eula is not accepted then logout and close the dialog
        this.clAuth.logout();
      }
      this.dispatchCloseDialog();
    });
  }

  async acceptEula(): Promise<void> {
    try {
      this.isSubmitted = true;
      const resp = await this.eulaService.saveAcceptanceConfirmation();
      LoggingService.info('[EulaComponent.AcceptEula] User EULA acceptance updated successfully', resp);
      this.eulaService.updateEulaStatus(true);
      this.dispatchCloseDialog();
    } catch (err) {
      this.isSubmitted = false;
      this.eulaService.updateEulaStatus(false);
      LoggingService.error('[Eula.Component]: Error in saving the eula response');
    }
  }

  dispatchCloseDialog(): void {
    if (!this.deviceDetectService.isMobile) {
      const matDialogRef: MatDialogRef<EulaComponent> = this.matDialog.getDialogById(this.matDialogId);
      if ( matDialogRef) {
        matDialogRef.close();
      }
    }
  }

  togglerTCSelection(): void {
    if ( !this.isTCClicked) {
      if (!this.tcWindowId ) {
        this.tcWindowId = window.open(this.clAuth.appInfo.url, '_blank');
      } else if ( !this.tcWindowId?.closed) {
        this.tcWindowId.focus();
      } else {
        this.tcWindowId = window.open(this.clAuth.appInfo.url, '_blank');
      }
    }
    this.isTCClicked = !this.isTCClicked;
  }

  ngOnDestroy(): void {
    if ( this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
