import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as microsoftTeams from '@microsoft/teams-js';
import { DOCUMENT } from '@angular/common';
import { MSTEAMS } from 'src/app/common/constants';
import { telUrl } from 'src/app/helpers/common-helper';
import { TeamsContextService } from 'src/app/services/teams-context.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-softphone-launcher',
  templateUrl: './softphone-launcher.component.html',
  styleUrls: ['./softphone-launcher.component.less'],
})
export class SoftphoneLauncherComponent implements OnInit {
  window: Window;

  constructor(
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MSTEAMS) public msTeams: typeof microsoftTeams,
    private teamsContextService: TeamsContextService
  ) {
    this.window = this.document.defaultView;
  }

  async ngOnInit(): Promise<void> {
    const context = await this.teamsContextService.getContext();
    if (context) {
      this.route.queryParams.subscribe((params) => {
          this.window.location.href = telUrl(params.number, params?.fromCallhistory);
      });

      timer(15000).subscribe(() => {
        this.window.opener.postMessage({message: 'success'});
        this.msTeams.authentication.notifySuccess('success');
      });
    }
  }
}
