<div *ngIf="showLoader">
  <app-load-contact></app-load-contact>
</div>
<div *ngIf="showContactInfo">
  <app-contact-details [contact]="contact" (dialed)="dialHandler()"></app-contact-details>
</div>
<div *ngIf="showMessage" class="message-container">
  <div class="message-header">{{ 'message.header' | translate }}</div>
  <div class="message">{{ message }}</div>
  <button class="ok-button" (click)="closeMessage()">OK</button>
</div>
