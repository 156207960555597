import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less'],
})
export class LoadingComponent implements OnInit {
  @Input() public loaderType;
  public repetition: number[] ;
  constructor() {}

  ngOnInit(): void {
    this.repetition = this.loaderType === 'gridLoader' ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] : [1, 2, 3, 4, 5];
  }
}
