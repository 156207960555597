import { Injectable } from '@angular/core';
import * as MicrosoftGraphClient from '@microsoft/microsoft-graph-client';
import { Observable, Subject } from 'rxjs';
import { permissions } from '../common/constants';
import { ContactActivity, ContactPresence } from '../enums/contact-presence';
import { ContactType } from '../enums/contact-type';
import { ContactUpdateEnum } from '../enums/contact-update-enum';
import {
  IAllContacts,
  IContactPhoneNumbers,
  IContactPhoto,
  IContactPresence,
  ISavedSpeedDialContact,
} from '../interfaces/contact';
import { IContactUpdate } from '../interfaces/contact-update';
import { IConversationMember } from '../interfaces/conversation';
import { Contact } from '../model/contact';
import { AuthService } from './auth.service';
import { TeamsAuthService } from './teams-auth.service';
import { LoggingService } from './logging.service';
import { SpeeddialsCloudlinkService } from './speeddials-cloudlink.service';
import { SpeedDial } from 'src/app/common/constants';

type ListType = 'first' | 'second';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  msGraphClient: MicrosoftGraphClient.Client;
  tokenProvider: AuthService | TeamsAuthService;
  speedDialContacts1 = [] as ISavedSpeedDialContact[];
  speedDialContacts2 = [] as ISavedSpeedDialContact[];
  speedDialContacts3 = [] as ISavedSpeedDialContact[]; // CL Contacts
  contactsUpdated = new Subject<IContactUpdate>();
  isCLLoggedIn = false;
  CachedPersonalcontacts: Promise<Contact[]>;

  constructor(
    private authService: AuthService,
    private teamsAuthService: TeamsAuthService,
    private speedDialClsvc: SpeeddialsCloudlinkService) {
    LoggingService.info(`[ContactService.constructor]: intialising MicrosoftGraphClient`);
    this.setAuthProviderForTeams();
  }

  get speedDialContactIds(): ISavedSpeedDialContact[] {
    return [...this.speedDialContacts1, ...this.speedDialContacts2, ...this.speedDialContacts3];
  }

  set speedDialContactIds(speedDialContacts: ISavedSpeedDialContact[]) {
    const allcontacts = [...speedDialContacts];
    const contactListOne = allcontacts.splice(0, 10);
    this.speedDialContacts1 = contactListOne;
    const contactListTwo = allcontacts.splice(0, 10);
    this.speedDialContacts2 = contactListTwo;
    this.speedDialContacts3 = allcontacts;
  }

  get contactsUpdatedObserver(): Observable<IContactUpdate> {
    return this.contactsUpdated.asObservable();
  }

  async setAuthProviderForTeams(forTeams = false): Promise<void> {
    LoggingService.info(
      `[ContactService.setAuthProviderForTeams]: intialising MicrosoftGraphClient with forTeams: ${forTeams}`
    );
    this.tokenProvider = forTeams ? this.teamsAuthService : this.authService;

    return new Promise<void>((resolve, reject) => {
      let authState = this.teamsAuthService.authState;
      this.msGraphClient = MicrosoftGraphClient.Client.init({
        authProvider: async (done) => {
          if (!authState) {
            LoggingService.info('[ContactService]: User is coming from login page, open login popup');
            try {
              authState = await this.tokenProvider.getAccessTokenWithExpiry(permissions);
            } catch (error) {
              reject('Failed to get acess token' + error);
            }
          } else if (!authState.isValid()) {
            LoggingService.info('[ContactService]: access token is expired, obtaining token silently');
            authState = await this.authService.getAccessTokenWithExpiry(permissions);
          }

          this.teamsAuthService.authState = authState;
          if (authState?.accessToken) {
            done(null, authState.accessToken);
            resolve();
          } else {
            done('Failed to get acess token', '');
            reject('Failed to get acess token');
          }
        },
      });
    });
  }

  async getContact(id: string): Promise<Contact> {
    LoggingService.info(`[ContactService.getContact]: id: ${id}`);
    try {
      const contact = await this.msGraphClient.api(`/users/${id}`).get();

      return new Contact(contact);
    } catch (error) {
      LoggingService.error(`[ContactService.getContact]: Failed to get contact ${error.body}`);
      throw JSON.parse(error.body);
    }
  }

  async getContactBatch(ids: string[]): Promise<Contact[]> {
    LoggingService.info(`[ContactService.getContactBatch]: ids: ${ids}`);
    if (ids.length === 0) {
      return [];
    }
    const $batch = {
      requests: ids.map((id, index) => {
        return {
          url: `/users/${id}`,
          method: 'GET',
          id: index + 1,
        };
      }),
    };

    try {
      const response = await this.msGraphClient.api('/$batch').post($batch);

      const contacts = response.responses
        .filter((r) => r.status === 200)
        .map((r) => r.body)
        .map((c) => new Contact(c));

      return contacts;
    } catch (error) {
      LoggingService.error(`[ContactService.getContactBatch]: Failed to get contacts ${error.body}`);
      throw JSON.parse(error.body);
    }
  }

  async getContacts(contactName = ''): Promise<IAllContacts[]> {
    LoggingService.info(`[ContactService.getContacts]: contactName: ${contactName}`);
    if (contactName === '') {
      return [];
    }


    const $directoryFilter = `(startsWith(givenName,'${contactName}') or startsWith(surName,'${contactName}') or startsWith(displayName,'${contactName}')) and accountEnabled eq ${true}`;
    const $outlookFilter = `startsWith(givenName,'${contactName}') or startsWith(surName,'${contactName}') or startsWith(displayName,'${contactName}')`;
    const $batch = {
      requests: [
        {
          url: `/users?$count=true&$top=5&$filter=${$directoryFilter}`,
          method: 'GET',
          id: '1',
        },
        {
          url: `/me/contacts?$count=true&$top=5&$filter=${$outlookFilter}`,
          method: 'GET',
          id: '2',
        },
      ],
    };

    try {
      const response = await this.msGraphClient.api('/$batch').post($batch);

      const contacts = response.responses
        .filter((r) => r.status === 200)
        .map((r) => {
          const value = r.body.value.map((c) => new Contact(c));
          if (r.id === '1') {
            return { type: ContactType.Directory, contacts: value } as IAllContacts;
          } else {
            return { type: ContactType.Outlook, contacts: value } as IAllContacts;
          }
        });
      LoggingService.info('[ContactService.getContacts]: retrieved contacts', contacts);

      return contacts;
    } catch (error) {
      LoggingService.error(`[ContactService.getContacts]: Failed to fetch users ${error.body}`);
      throw JSON.parse(error.body);
    }
  }

  async getContactsByEmail(emails: any[] | string = []): Promise<IAllContacts[]> {
    LoggingService.info(`[ContactService.getContacts]: contactEmail: ${emails}`);

    if ( typeof emails === 'string') {
      emails = [emails];
    }

    if (!emails.length) {
      return Promise.resolve([]);
    }

    const batchRequests =  await this.generateBatchRequestsByEmail(emails);

    try {
      const responses =  await Promise.all(batchRequests);
      const contactResponseFlated =  responses.reduce((resultArray, response: any) =>
      resultArray =  [...resultArray, ...response.responses], [])
      .flat();
      return this.buildContactResponse(contactResponseFlated);
    } catch (error) {
      LoggingService.error(`[ContactService.getContactsByEmail]: Failed to fetch users ${error.body}`);
      throw JSON.parse(error.body);
    }
  }

  async getContactsByPhoneNumbers(phoneNos: string[]= []): Promise<IAllContacts[]> {
    let batchRequests = [];
    LoggingService.info(`[ContactService.getContactsByPhoneNumber]: Phone Numbers : ${phoneNos}`);
    if (!phoneNos.length) {
      return Promise.resolve([]);
    }
    batchRequests = await this.generateMSBatchRequestsByPhoneNos(phoneNos); // contains the array of batch requests
    try {
      const responses =  await Promise.all(batchRequests);
      const contactResponseFlated =  responses.reduce((resultArray, response: any) =>
      resultArray =  [...resultArray, ...response.responses], [])
      .flat();
      return this.buildPhoneContactResponse(contactResponseFlated);
    } catch (err) {
      LoggingService.error(`[ContactService.getContactsByPhoneNumber]: Failed to fetch users ${err.body}`);
      throw JSON.parse(err.body);
    }
  }

  async getPersonalContacts(): Promise<Contact[]> {
    LoggingService.info(`[ContactService.getPersonalContacts]:`);
    try {
      const response = await this.msGraphClient.api(`/me/contacts?$top=500`).get();
      this.CachedPersonalcontacts = response.value.map((c) => new Contact(c));
      return this.CachedPersonalcontacts;
    } catch (error) {
      LoggingService.error(`[ContactService.getPersonalContacts]: Failed to fetch personal contacts ${error.body}`);
      throw JSON.parse(error.body);
    }
  }

  async getCachedPersonalContacts(): Promise<Contact[]> {
    try {
      return this.CachedPersonalcontacts;
   } catch (error) {
    LoggingService.error(`[ContactService.getPersonalContacts]: Failed to getCachedPersonalContacts ${error.body}`);
   }
  }

  async getContactPhoto(id: string): Promise<IContactPhoto> {
    LoggingService.info(`[ContactService.getContactPhoto]: id: ${id}`);
    try {
      const photo = await this.msGraphClient.api(`/users/${id}/photo/$value`).get();
      const photoUrl = URL.createObjectURL(photo);
      return { id, photo: `url(${photoUrl})` } as IContactPhoto;
    } catch (error) {
      LoggingService.info(`[ContactService.getContactPhoto]: Failed to get contact photo ${error.body}`);
      return {
        id,
        photo: '',
      };
    }
  }

  async getContactPhones(id: string): Promise<IContactPhoneNumbers> {
    LoggingService.info(`[ContactService.getContactPhoneNumber]: id: ${id}`);
    try {
      const response = await this.msGraphClient
        .api(`/users/${id}/profile/phones`)
        .version('beta')
        .select('number,type')
        .get();

      return {
        id,
        allPhones: response.value,
      } as IContactPhoneNumbers;
    } catch (error) {
      LoggingService.info(`[ContactService.getContactPhoneNumber]: Failed to get contact phone numbers ${error.body}`);

      return {
        id,
        allPhones: [],
      };
    }
  }

  async getContactPresence(id: string): Promise<IContactPresence> {
    LoggingService.info(`[ContactService.getContactPresence]: id: ${id}`);
    try {
      const response = await this.msGraphClient.api(`/users/${id}/presence`).get();

      return {
        id,
        presence: response.availability,
        activity: response.activity,
      } as IContactPresence;
    } catch (error) {
      LoggingService.info(`[ContactService.getContactPresence]: Failed to get contact presence ${error.body}`);

      return {
        id,
        presence: ContactPresence.PresenceUnknown,
        activity: ContactActivity.PresenceUnknown,
      };
    }
  }

  async getPresenceForUsers(ids: string[]): Promise<Map<string, IContactPresence>> {
    LoggingService.info(`[ContactService.getContactPresence for multiple users ]: id: ${[...ids]}`);
    const responseResult = new Map();
    try {
      const body = { ids };
      const presenceResponse = await this.msGraphClient
        .api(`/communications/getPresencesByUserId`)
        .version('v1.0')
        .post(JSON.stringify(body));
      if (presenceResponse) {
        presenceResponse.value.forEach((presence) => {
          responseResult.set(presence.id, {
            id: presence.id,
            presence: presence.availability,
            activity: presence.activity,
          } as IContactPresence);
        });
      }
      return Promise.resolve(responseResult);
    } catch (error) {
      LoggingService.info(`[ContactService.getContactPresence]: Failed to get contact presence ${error.body}`);

      return Promise.resolve(responseResult);
    }
  }

  async getContactPhotoBatch(ids: string[]): Promise<IContactPhoto[]> {
    LoggingService.info(`[ContactService.getContactPhotoBatch]: ids: ${ids}`);
    if (ids.length === 0) {
      return [];
    }
    const $batch = {
      requests: ids.map((id, index) => {
        return {
          url: `/users/${id}/photo/$value`,
          method: 'GET',
          id,
        };
      }),
    };

    try {
      const response = await this.msGraphClient.api('/$batch').post($batch);

      const photos = response.responses
        .filter((r) => r.status === 200)
        .map((r) => {
          return { id: r.id, photo: 'data:image/jpeg;base64,' + r.body } as IContactPhoto;
        });

      return photos;
    } catch (error) {
      LoggingService.error(`[ContactService.getContactPhotoBatch]: Failed to get contact photos ${error.body}`);
      throw JSON.parse(error.body);
    }
  }

  async getSpeedDialContactIdsListOne(): Promise<ISavedSpeedDialContact[]> {
    return await this.getSpeedDialContactIdsList('first');
  }

  async getSpeedDialContactIdsListTwo(): Promise<ISavedSpeedDialContact[]> {
    return await this.getSpeedDialContactIdsList('second');
  }

  async getSpeedDialContactIdsListCL(): Promise<ISavedSpeedDialContact[]> {
    LoggingService.info('[contactService.getSpeedDialContactIdsListCL]');
    const response = await this.speedDialClsvc.getCloudlinkSpeedDials();
    const uniuqeItems: ISavedSpeedDialContact[] = [];
    response.forEach((item) => {
      if (![...this.speedDialContacts1, ...this.speedDialContacts2].some(res => res.i === item.i)) {
        uniuqeItems.push(item);
      }
    });
    this.speedDialContacts3 = uniuqeItems;
    return uniuqeItems;
  }

  async getSpeedDialContactIdsList(listType: ListType): Promise<ISavedSpeedDialContact[]> {
    LoggingService.info(
      `[ContactService.getSpeedDialContactIdsList]: getting speed dial contacts list type : ${listType}`
    );

    try {
      const response = await this.msGraphClient.api(`/me/extensions/mitel-speeddial-contacts-${listType}`).get();

      if (listType === 'first') {
        this.speedDialContacts1 = response.contacts;
      } else {
        this.speedDialContacts2 = response.contacts;
      }
      return response.contacts;
    } catch (error) {
      const errorBody = error.body;
      if (JSON.parse(errorBody).code !== 'ResourceNotFound') {
        LoggingService.error(
          `[ContactService.getSpeedDialContactIdsList]: Failed to fetch contacts in speed dial list  ${error.body}`
        );
        throw JSON.parse(error.body);
      }

      const extension = {
        '@odata.type': 'microsoft.graph.openTypeExtension',
        extensionName: `mitel-speeddial-contacts-${listType}`,
        contacts: [],
      };

      try {
        await this.msGraphClient.api('/me/extensions').post(extension);

        return [];
      } catch (error) {
        LoggingService.error(
          `[ContactService.getSpeedDialContactIdsList]: Failed to create dial list open extention speed dial list  ${error.body}`
        );
        throw JSON.parse(error.body);
      }
    }
  }

  async upadateSpeedDialContactsListOne(contactIds: ISavedSpeedDialContact[]): Promise<void> {
    return await this.upadateSpeedDialContacts('first', contactIds);
  }

  async upadateSpeedDialContactsListTwo(contactIds: ISavedSpeedDialContact[]): Promise<void> {
    return await this.upadateSpeedDialContacts('second', contactIds);
  }

  async upadateSpeedDialContactsCloudLink(contactIds: ISavedSpeedDialContact[]): Promise<void> {
    return await this.speedDialClsvc.createCloudlinkSpeedDials(contactIds);
  }

  async upadateSpeedDialContacts(listType: ListType, contactIds: ISavedSpeedDialContact[]): Promise<void> {
    LoggingService.info(
      `[ContactService.upadateSpeedDialContacts]: list type: ${listType}, contactIds: ${JSON.stringify(contactIds)}`
    );
    contactIds = contactIds.map((c) => ({ i: c.i, n: (c.n || '').trim().substr(-4) } as ISavedSpeedDialContact));
    const extension = {
      contacts: contactIds,
    };

    try {
      await this.msGraphClient.api(`/me/extensions/mitel-speeddial-contacts-${listType}`).update(extension);
    } catch (error) {
      LoggingService.error(
        `[ContactService.upadateSpeedDialContactsListOne]: Failed to update contacts in speed dial list  ${error.body}`
      );
      throw JSON.parse(error.body);
    }
  }

  async addContact(contact: Contact): Promise<void> {
    LoggingService.info(`[ContactService.addContact]: contact: ${JSON.stringify(contact)}`);
    if (this.speedDialContactIds.some((c) => contact.id === c.i)) {
      return;
    }

    const allContacts = [...this.speedDialContactIds, { i: contact.id, n: contact.selectedPhoneNumber }];

    try {
      await this.updateSpeedDialContacts(allContacts);

      this.contactsUpdated.next({ operation: ContactUpdateEnum.Add, contactDetails: new Contact(contact) });
    } catch (error) {
      LoggingService.error(`[ContactService.addContact]: Failed to add contact in speed dial list ${error.body}`);
      throw error;
    }
  }

  async updateContact(contact: Contact): Promise<void> {
    LoggingService.info(`[ContactService.deleteContact]: contact: ${JSON.stringify(contact)}`);
    try {
      const allContacts = [...this.speedDialContactIds];
      const speedDialContact = allContacts.find((c) => contact.id === c.i);
      speedDialContact.n = contact.selectedPhoneNumber;

      await this.updateSpeedDialContacts(allContacts);
    } catch (error) {
      LoggingService.error(`[ContactService.updateContact]: Failed to update contact in speed dial list ${error.body}`);
      throw error;
    }
  }

  async deleteContact(contact: Contact): Promise<void> {
    LoggingService.info(`[ContactService.deleteContact]: contact: ${JSON.stringify(contact)}`);
    try {
      const allContacts = [...this.speedDialContactIds].filter((c) => c.i !== contact.id);

      await this.updateSpeedDialContacts(allContacts);

      this.contactsUpdated.next({ operation: ContactUpdateEnum.Delete, contactDetails: new Contact(contact) });
    } catch (error) {
      LoggingService.error(`[ContactService.deleteContact]: Failed to delete contact in speed dial list ${error.body}`);
      throw error;
    }
  }

  private async updateSpeedDialContacts(allContacts: ISavedSpeedDialContact[]): Promise<void> {
    const contactListOne = allContacts.splice(0, 10);
    await this.upadateSpeedDialContactsListOne(contactListOne);
    this.speedDialContacts1 = contactListOne;

    const contactListTwo = allContacts.splice(0, 10);
    await this.upadateSpeedDialContactsListTwo(contactListTwo);
    this.speedDialContacts2 = contactListTwo;
    if (this.isCLLoggedIn) {
      await this.upadateSpeedDialContactsCloudLink(allContacts);
      this.speedDialContacts3 = allContacts;
    }
  }

  public getSpeedDialsLimit(): boolean {
    return this.isCLLoggedIn ?  (this.speedDialContactIds.length < SpeedDial.MaxSpeedDialWithCL)
    : this.speedDialContactIds.length < SpeedDial.MaxSpeedDialWithoutCL;
  }

  async getMyDetails(): Promise<Contact> {
    LoggingService.info(`[ContactService.getMyDetails]`);
    try {
      const user = await this.msGraphClient.api('me').get();

      return user;
    } catch (error) {
      LoggingService.error(`[ContactService.getMyDetails]: Failed to get my details ${error.body}`);
      throw JSON.parse(error.body);
    }
  }

  async getConversationMembers(conversationID: string): Promise<IConversationMember[]> {
    try {
      const response = await this.msGraphClient.api(`me/chats/${conversationID}/members`).get();

      return response.value;
    } catch (error) {
      LoggingService.error(`[ContactService.getConversation]: Failed to get my details ${error.body}`);
      throw JSON.parse(error.body);
    }
  }

  buildContactResponse(contactsResponseList: any[]): IAllContacts[]{
    LoggingService.info('[contactservice.buildContactResponse]');
    return contactsResponseList.filter((r) => r.status === 200)
    .map((c) => c.body.value)
    .map((r) => {
      const value = r.map((c) => new Contact(c));
      if (r.id === '1') {
        return { type: ContactType.Directory, contacts: value } as IAllContacts;
      } else {
        return { type: ContactType.Outlook, contacts: value } as IAllContacts;
      }
    });
  }

  buildPhoneContactResponse(contactsResponseList: any[]): any[]{
    return contactsResponseList.filter((r) => r.status === 200 )
    .map((c) => {
      if (c.body.value.length > 0) {
       return { phone: c.id, contacts: new Contact(c.body.value[0]) };
      } else {
        return { phone: c.id, contacts: null };
      }
    });
  }

  async generateMSBatchRequestsByPhoneNos(phoneNos: string[]): Promise<Promise<any>[]> {
    LoggingService.info('[contactservice.generateMSBatchRequestsByPhoneNos]: PhoneNos: ', phoneNos);
    const msBatchLimit = 20;
    const batchRequests = [];
    const batch = phoneNos.length > msBatchLimit ? Math.ceil(phoneNos.length / msBatchLimit ) : 1;
    let outerCount = 1;
    while (outerCount <= batch) {
      let innerCount = 0;
      const limit = (outerCount * msBatchLimit) > phoneNos.length ? phoneNos.length - ((outerCount - 1) * msBatchLimit) : msBatchLimit;
      const $batch = [];
      while (innerCount < limit) {
        $batch.push(
          {
            id: phoneNos[((outerCount - 1) * msBatchLimit) + innerCount],
            request: new Request ( '/users?$filter=businessPhones/any(p:p eq \''
            + encodeURIComponent(phoneNos[((outerCount - 1) * msBatchLimit) + innerCount]) + '\') or mobilePhone eq \''
            + encodeURIComponent(phoneNos[((outerCount - 1) * msBatchLimit) + innerCount]) + '\'&$count=true',
            {
              method: 'GET',
              headers: {
                ConsistencyLevel: 'eventual',
              }
            })
          });
        innerCount++;
      }
      const batchReqContent = new MicrosoftGraphClient.BatchRequestContent($batch);
      const  content =  await batchReqContent.getContent();
      batchRequests.push(this.msGraphClient.api('/$batch').post(content));
      outerCount++;
    }
    return Promise.resolve(batchRequests);
  }

  async generateBatchRequestsByEmail(emailIds: string[]): Promise<Promise<any>[]> {
    LoggingService.info('[contactservice.generateBatchRequestsByEmail]: emailsIds: ', emailIds);
    const msBatchLimit = 20;
    const batchRequests = [];
    if (emailIds.length > 0) {
      // We will pass 6 emails in a single request among multiple requests in a single batch request
      const batch = (emailIds.length / 6 ) > msBatchLimit ? Math.ceil((emailIds.length / 6) / msBatchLimit ) : 1;
      let outerCount = 1;
      while (outerCount <= batch) {
        let innerCount = 0;
        const limit =
        (outerCount * msBatchLimit * 6 ) > emailIds.length ? emailIds.length - ((outerCount - 1) * msBatchLimit * 6) : msBatchLimit;
        const $batch = [];
        while (innerCount < limit) {
          // tslint:disable-next-line: quotemark
          const reqEmailIds = emailIds.slice(innerCount, innerCount + 6).join("','");
          $batch.push(
            {
              id: ((outerCount - 1) * msBatchLimit) + innerCount + 1,
              request: new Request ( encodeURI(`/users?$filter=mail in('${reqEmailIds}') or userPrincipalName in('${reqEmailIds}')&$count=true`),
              {
                method: 'GET',
                headers: {
                  ConsistencyLevel: 'eventual',
                }
              })
            });
          innerCount = innerCount + 6;
        }
        const batchReqContent = new MicrosoftGraphClient.BatchRequestContent($batch);
        const  content =  await batchReqContent.getContent();
        batchRequests.push(this.msGraphClient.api('/$batch').post(content));
        outerCount++;
      }
    } else {
      // tslint:disable-next-line:quotemark
      Promise.resolve([]);
    }

    return Promise.resolve(batchRequests);
  }

}
