import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DialerComponent } from './components/dialer/dialer.component';
import { SoftphoneLauncherComponent } from './components/softphone-launcher/softphone-launcher.component';
import { TabComponent } from './components/tab/tab.component';
import { AuthPopupComponent } from './components/auth-popup/auth-popup.component';
import { WebComponent } from './components/web/web.component';
import { LoginHelperComponent } from './components/login-helper/login-helper.component';
import { AuthGuard } from './guards/auth.guard';
import { ConversationMemberComponent } from './components/conversation-member/conversation-member.component';
import { IssueReportComponent } from './components/issue-report/issue-report.component';
import { ClAuthPopupComponent } from './components/cl-auth-popup/cl-auth-popup.component';

const routes: Routes = [
  { path: 'loginhelper', component: LoginHelperComponent },
  { path: 'compose-loginhelper', component: LoginHelperComponent },
  { path: 'dialer', component: DialerComponent },
  { path: 'teamsauthpopup', component: AuthPopupComponent },
  { path: 'tab', component: TabComponent, canActivate: [AuthGuard] },
  { path: 'conv-member', component: ConversationMemberComponent, canActivate: [AuthGuard] },
  { path: 'web', component: WebComponent },
  { path: 'softphonelauncher', component: SoftphoneLauncherComponent },
  { path: 'report-issue', component: IssueReportComponent },
  { path: 'clauthpopup', component: ClAuthPopupComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
