import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MSTEAMS } from '../common/constants';
import * as microsoftTeams from '@microsoft/teams-js';
import { app} from '@microsoft/teams-js';

import { AppUtils } from '../common/utils';

@Injectable({
  providedIn: 'root',
})
export class TeamsContextService {
  window: Window;
  authWindow: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MSTEAMS) public msTeams: typeof microsoftTeams
    ) {
    this.window = this.document.defaultView;
  }

  getContext(): Promise<app.Context> {
    return new Promise(async (resolve, reject) => {
      try {
        if ( !this.msTeams.app.isInitialized()) {
          await this.msTeams.app.initialize();
        }
        const context = await this.msTeams.app.getContext();
        if ( context) {
          resolve(context);
        } else {
          reject('Failed to get teams context');
        }
      } catch (err) {
        if ( this.window.location.href  === this.window.parent.location.href ) { // standalone app
          const context =  {
            app : {
              locale: 'en',
              host: { clientType : 'web'},
              sessionId: Math.round(Math.random() * 1000).toString(),
              user: {userPrincipalName: ''},
            },
            page: null
          };
          resolve(context as any);
          return;
        }
        reject(err);
      }
    });
  }


  authenticate(popupInfo: any): Promise<any> {
    if ( this.msTeams.app.isInitialized()) {
      return this.msTeams.authentication.authenticate(popupInfo);
    } else {
      return AppUtils.initNativeAuthWindow(this.authWindow, popupInfo.url, 400, 200);
    }
  }

  submitTask(): void {
    this.msTeams.dialog.submit();
  }
}
