import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AlertConfig, AlertOptions } from '../interfaces/alert-options';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private toastr: ToastrService, private translate: TranslateService) {}

  async info(messageKey: string, options?: AlertOptions): Promise<number> {
    return this.showAlert(AlertType.INFO, messageKey, options);
  }

  async error(messageKey: string, options?: AlertOptions): Promise<number> {
    return this.showAlert(AlertType.ERROR, messageKey, options);
  }

  async warning(messageKey: string, options?: AlertOptions): Promise<number> {
    return this.showAlert(AlertType.WARNING, messageKey, options);
  }

  async success(messageKey: string, options?: AlertOptions): Promise<number> {
    return this.showAlert(AlertType.SUCCESS, messageKey, options);
  }

  private async showAlert(type: AlertType, messageKey: string, options?: AlertOptions): Promise<number> {
    // Race condition where translations could be loaded after this function is hit, need to use the 'get' function instead of instant
    const translated = await this.translate
      .get(messageKey, !!options && !!options.translationKeys ? options.translationKeys : {})
      .pipe(take(1))
      .toPromise();

    const optionsUsed: AlertOptions = {
      title: '',
      allowClose: true,
      duration: 6000,
      positionClass: 'toast-top-center-overlay',
    };

    if (!!options) {
      optionsUsed.title = !!options.title && options.title.trim().length > 0 ? options.title : '';
      optionsUsed.allowClose = 'allowClose' in options ? options.allowClose : true;
      optionsUsed.duration = !!options.duration ? options.duration : 0;
      optionsUsed.positionClass = !!options.positionClass ? options.positionClass : 'toast-top-center-overlay';
      optionsUsed.showAction = !!options.showAction;
      optionsUsed.actionText = options.actionText;
      optionsUsed.action = options.action;
      optionsUsed.showClickForDetails = !!options.showClickForDetails;
    }

    const alertConfig: Partial<AlertConfig> = {
      timeOut: optionsUsed.duration,
      closeButton: optionsUsed.allowClose,
      disableTimeOut: !(!!optionsUsed.duration && optionsUsed.duration > 0),
      positionClass: optionsUsed.positionClass,
      showClickForDetails: optionsUsed.showClickForDetails,
      showAction: optionsUsed.showAction,
      actionText: optionsUsed.actionText,
      action: optionsUsed.action,
    };
    let alertId: number;

    switch (type) {
      case AlertType.SUCCESS:
        alertId = this.toastr.success(translated, optionsUsed.title, alertConfig).toastId;
        break;
      case AlertType.ERROR:
        alertId = this.toastr.error(translated, optionsUsed.title, alertConfig).toastId;
        break;
      case AlertType.WARNING:
        alertId = this.toastr.warning(translated, optionsUsed.title, alertConfig).toastId;
        break;
      case AlertType.INFO:
      default:
        alertId = this.toastr.info(translated, optionsUsed.title, alertConfig).toastId;
        break;
    }
    return alertId;
  }
}

enum AlertType {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}
