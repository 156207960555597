import { Injectable } from '@angular/core';
import Analytics from '@aws-amplify/analytics';
import AwsAuth from '@aws-amplify/auth';
import { environment } from 'src/environments/environment';
import { EventType } from '../types/anlytics-event';
import { AuthService } from './auth.service';
import { LoggingService } from './logging.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private authService: AuthService) {}

  configure(): void {
    try{
      LoggingService.info(`[AnalyticsService.configure]: configuring AWS anlytics`);
      const amplifyConfig = {
        Auth: {
          identityPoolId: environment.awsAmplify.identityPoolId,
          region: environment.awsAmplify.region,
        },
      };

      AwsAuth.configure(amplifyConfig);

      const analyticsConfig = {
        Analytics: {
          disabled: false,
          autoSessionRecord: false,
          AWSPinpoint: {
            appId: environment.awsAmplify.appId,
            region: environment.awsAmplify.region,
          },
        },
      };

      Analytics.configure(analyticsConfig);
    } catch (err) {
      LoggingService.error('[AnalyticsService.configure]: configuring AWS anlytics failed', err);
    }
  }

  record(name: EventType, attributes?: any, metrics?: any): void {
    try{
      LoggingService.info(
        `[AnalyticsService.record]: name: ${name}, attributes: ${JSON.stringify(attributes)}, metrics: ${JSON.stringify(
          metrics
        )}`
      );
      const userId = this.authService.getUsername();
      const newAttributes = {
        ...attributes,
        userId: CryptoJS.SHA256(userId).toString(),
      };

      Analytics.record(name, newAttributes, metrics);
    } catch (err) {
      LoggingService.error('[AnalyticsService.record]: Failed to record', err);
    }
  }
}
