export class AppUtils {
    public static getInitials(nameFull: string): string {
        if (nameFull?.length > 0) {
            const names = nameFull.split(' ');
            return names.length > 1
              ? names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase()
              : names[0].charAt(0).toUpperCase();
        }
        return '';
    }

    public static getRandomColor(): string {
        const appColor = {
            blueMagenta: '#866ebb',
            steelBlue: '#4887bc',
            mandy: '#c37996',
            grandis: '#48b7cb',
            aluminium: '#d4b39e',
            pastelGreen: '#c6be5a',
            padua: '#80aeb1',
            skyBlue: '#80d9eb',
            wewak: '#ee778e',
            equator: '#e1a966',
            flamingo: '#eb8162',
            portage: '#a09cd4',
            gray: '#7e858b',
          };
        const colors = [
            appColor.blueMagenta,
            appColor.steelBlue,
            appColor.mandy,
            appColor.grandis,
            appColor.aluminium,
            appColor.pastelGreen,
            appColor.padua,
            appColor.skyBlue,
            appColor.wewak,
            appColor.equator,
            appColor.flamingo,
            appColor.portage,
            appColor.gray,
        ];
        return colors[Math.floor(Math.random() * 12)];
    }

    public static initNativeAuthWindow(authWin: any, locationuri: any, width: number= null, height: number= null): Promise<any> {
        if ( authWin && !authWin?.closed ) { // if not closed the focus on the current
          authWin.focus();
          return;
        }
        const winDimention = `width=${width || 450}, height=${height || 700}`;
        authWin = window.open(
         locationuri,
          '_blank',
          winDimention);
        let timeOut;
        return new Promise((resolve, reject) => {
          window.onmessage = ($event)  => {
            if ($event.origin === window.origin) {
              let data;
              try {
                data =  JSON.parse($event.data);
              } catch (err){
                data = $event.data;
              }
              switch (data?.message) {
                case 'success' :
                  this.closeAuthWindow(authWin);
                  resolve(data?.payload);
                  break;
                case 'failure':
                  this.closeAuthWindow(authWin);
                  reject(data?.payload);
                  break;
                case 'default':
                  break;
              }
            }
          };
          timeOut = window.setTimeout(() => {
            this.closeAuthWindow(authWin);
            clearTimeout(timeOut);
            reject('failure');
          }, 900000); // resolve the promise incase there is no update from the opened window in 15 mins
        });
      }

      public static closeAuthWindow(authWin: any): void {
        authWin.close();
        authWin = null;
        window.onmessage = null;
      }
}
