import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import * as microsoftTeams from '@microsoft/teams-js';
import { MSTEAMS } from '../../common/constants';
import { ActivatedRoute } from '@angular/router';
import { CloudAuthenticationService } from '../../services/cloud-authentication.service';
import { TeamsContextService } from '../../services/teams-context.service';
const HOST_CLIENT_TYPE = 'teamsHostClientType';

@Component({
  selector: 'app-cl-auth-popup',
  templateUrl: './cl-auth-popup.component.html',
  styleUrls: ['./cl-auth-popup.component.less'],
})
export class ClAuthPopupComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    @Inject(MSTEAMS) public msTeams: typeof microsoftTeams,
    private clAuth: CloudAuthenticationService,
    private teamsContextService: TeamsContextService
  ) {
  }

  ngOnInit(): void {
    this.subscribeCLAuthCode();
  }

  async subscribeCLAuthCode(): Promise<any> {
    if (this.route.snapshot.queryParams.hasOwnProperty('code')) {
      const context = await this.teamsContextService.getContext();
      if ( context?.app?.host?.clientType) {
        window.localStorage.setItem(HOST_CLIENT_TYPE, ( context?.app?.host?.clientType || 'web')); // Set the host system
      }
      if (context?.app?.sessionId ) {
        this.notifyParentWindow('success', this.route.snapshot.queryParams.code);
      } else {
        await this.clAuth.resolveAuthToken(this.route.snapshot.queryParams.code);
        this.notifyParentWindow('success', '');
      }
    } else {
      const clUrl = this.clAuth.buildCloudLinkAuthUrl();
      if (clUrl) {
        window.location.href = clUrl; // Navigate to the CL Auth page for Authentication
      } else {
        this.notifyParentWindow('failure', 'Unable to get the auth redirection URL.');
      }
    }
  }

  public notifyParentWindow(notificationType: string, message: any ): void {
    if ( !this.msTeams.app.isInitialized()) { // if individual window then send a post message
      window.opener.postMessage(JSON.stringify({message: notificationType, payload: message}), window.origin);
    } else {
      if ( notificationType === 'success') {
        this.msTeams.authentication.notifySuccess(message);
      } else {
        this.msTeams.authentication.notifyFailure(message);
      }
    }
  }
}
