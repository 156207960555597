<div class="add-speed-dial-background">
  <div class="add-speed-dial-dialog" >
    <div class="dialog-title">{{ 'speed-dial.add.dialog-title' | translate }}</div>
    <app-contact-list
      (selected)="addContact($event)"
      [placeHolderText]="searchPlaceHolderText"
      [action]="'add'"
    ></app-contact-list>
    <div class="dialog-actions">
      <span class="dialog-action dialog-action-cancel" (click)="raiseCancelAddEvent()">{{
        'speed-dial.actions.cancel' | translate
      }}</span>
    </div>
  </div>
</div>
