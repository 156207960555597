import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tooltip]',
})
export class TooltipDirective implements AfterViewInit {
  private tooltipText = '';
  @Input('tooltip')
  set title(title: string) {
    this.tooltipText = title || '';
  }

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (element.offsetWidth < element.scrollWidth) {
        element.title = element.innerHTML;
      } else {
        element.title = this.tooltipText;
      }
    }, 300);
  }
}
