import { Pipe, PipeTransform } from '@angular/core';
import { ICall } from '../interfaces/call-history';

@Pipe({
  name: 'filtercalls',
})
export class CallHistoryPipe implements PipeTransform {
  transform(calls: ICall[], type: string, search: string): ICall[] {
    switch (type) {
      case 'missed':
        return calls
          .filter((call) => call.direction === 'inbound' && !call.completed)
          .filter((mCalls) => {
            return search?.length ? this.searchCalls(mCalls, search) : true;
          }).sort((a: ICall, b: ICall) => Number(b.createdOn) - Number(a.createdOn));
      case 'all':
        return calls.filter((mCalls) => {
          return search?.length ? this.searchCalls(mCalls, search) : true;
        }).sort((a: ICall, b: ICall) => Number(b.createdOn) - Number(a.createdOn));
    }
  }

  searchCalls(call: ICall, searchText: string): boolean {
    searchText = searchText.toLowerCase();
    return call.direction === 'inbound' ?
    (call.fromName !== 'undefined' && call.fromName.toLowerCase().indexOf(searchText) > -1) || call.from.indexOf(searchText) === 0 :
    (call.toName !== 'undefined' && call.toName.toLowerCase().indexOf(searchText) > -1) || call.to.indexOf(searchText) === 0;
  }
}

