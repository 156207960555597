import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IPhoneNumber } from 'src/app/interfaces/contact';
import { Contact } from 'src/app/model/contact';

@Component({
  selector: 'app-phone-number-list',
  templateUrl: './phone-number-list.component.html',
  styleUrls: ['./phone-number-list.component.less']
})
export class PhoneNumberListComponent implements OnInit {

  @Input() contact: Contact;
  @Output() selected: EventEmitter<void> = new EventEmitter();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  selectPhoneNumber(contact: Contact, phone: IPhoneNumber): void {
    contact.selectPhoneNumber(phone.number);
    this.selected.emit();
  }

  phoneNumberType(phone: IPhoneNumber): string {
    let phoneNumber: string;
    switch (phone.type) {
      case 'business': {
        this.translate.get('phone-type.work').subscribe((text: string) => {
          phoneNumber =  text + phone.number;
        });
        break;
      }
      case 'mobile': {
        this.translate.get('phone-type.mobile').subscribe((text: string) => {
          phoneNumber =  text + phone.number;
        });
        break;
      }
      case 'home': {
        this.translate.get('phone-type.home').subscribe((text: string) => {
          phoneNumber =  text + phone.number;
        });
        break;
      }
      default: {
        phoneNumber = phone.number;
        break;
      }
    }
    return phoneNumber;
  }

}
