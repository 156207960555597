import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from '@angular/cdk/layout';
import { AppComponent } from './app.component';
import { AuthPopupComponent } from './components/auth-popup/auth-popup.component';
import { TabComponent } from './components/tab/tab.component';
import { WebComponent } from './components/web/web.component';
import { DialerComponent } from './components/dialer/dialer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SoftphoneLauncherComponent } from './components/softphone-launcher/softphone-launcher.component';
import { LoginHelperComponent } from './components/login-helper/login-helper.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpLoaderFactory, setupLang } from './helpers/common-helper';
import { DialerContainerComponent } from './components/dialer-container/dialer-container.component';
import { AddSpeedDialComponent } from './components/speed-dial/add-speed-dial/add-speed-dial.component';
import { SpeedDialListComponent } from './components/speed-dial/speed-dial-list/speed-dial-list.component';
import { ContactListComponent } from './components/contact/contact-list/contact-list.component';
import * as microsoftTeams from '@microsoft/teams-js';
import { MSTEAMS } from './common/constants';
import { LoadingComponent } from './components/loading/loading.component';
import { ConversationMemberComponent } from './components/conversation-member/conversation-member.component';
import { ContactComponent } from './components/speed-dial/speed-dial-contact/speed-dial-contact.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { RandomColorDirective } from './directives/random-color.directive';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ContactDetailsComponent } from './components/contact/contact-details/contact-details.component';
import { LoadContactComponent } from './components/load-contact/load-contact.component';
import { PhoneNumberListComponent } from './components/phone-number-list/phone-number-list.component';
import { LanguageService } from './services/language.service';
import { PresenceDirective } from './directives/presence.directive';
import { ReportIssueFormModule } from '@mitel-internal/cloudlink-components-catalogue';
import { IssueReportComponent } from './components/issue-report/issue-report.component';
import { ClAuthPopupComponent } from './components/cl-auth-popup/cl-auth-popup.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SvgModule } from '@mitel-internal/cloudlink-components-catalogue';
import { CallHistoryComponent } from './components/call-history/call-history.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CallHistoryPipe } from './pipes/callhistory.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FilterPipe } from './pipes/filter.pipe';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { EulaComponent } from './components/eula/eula.component';
import { FormsModule } from '@angular/forms';
import { VoicemailComponent } from './components/voicemail/voicemail.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthPopupComponent,
    TabComponent,
    WebComponent,
    DialerComponent,
    SoftphoneLauncherComponent,
    LoginHelperComponent,
    DialerContainerComponent,
    AddSpeedDialComponent,
    SpeedDialListComponent,
    ContactListComponent,
    LoadingComponent,
    ConversationMemberComponent,
    ContactComponent,
    TooltipDirective,
    RandomColorDirective,
    SpinnerComponent,
    ContactDetailsComponent,
    LoadContactComponent,
    PhoneNumberListComponent,
    PresenceDirective,
    IssueReportComponent,
    ClAuthPopupComponent,
    CallHistoryComponent,
    CallHistoryPipe,
    FilterPipe,
    UserProfileComponent,
    EulaComponent,
    VoicemailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ReportIssueFormModule,
    HttpClientModule,
    SvgModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      countDuplicates: true,
      closeButton: true,
      disableTimeOut: true,
      enableHtml: false,
      maxOpened: 10,
      easeTime: 200,
      newestOnTop: true,
      positionClass: 'toast-top-center-overlay',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: false,
      tapToDismiss: false,
    }),
    LayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    InfiniteScrollModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule
  ],
  providers: [
    { provide: MSTEAMS, useValue: microsoftTeams },
    {
      provide: APP_INITIALIZER,
      useFactory: setupLang,
      deps: [LanguageService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [IssueReportComponent],
})
export class AppModule {}
