import { Injectable, Inject } from '@angular/core';
import { AppInfo, AuthenticationService as CloudAuth, Claims, Config, Token } from '@mitel/cloudlink-sdk';
import { BehaviorSubject} from 'rxjs';
import { environment } from '../../environments/environment';
import * as microsoftTeams from '@microsoft/teams-js';
import { MSTEAMS } from '../common/constants';
import { LoggingService } from './logging.service';
import { TranslateService } from '@ngx-translate/core';
import * as VersionInfo from 'src/environments/version';
import { AppUtils } from '../common/utils';

const MITEL_CUST = '@mitel/customer';
const MITEL_CUST_CL_ACC_ID = '@mitel/CLAccountId';
const MITEL_CUST_INFO = '@mitel/customer/info';
const APP_NAME_INITIALS = 'parakeet';
const HOST_CLIENT_TYPE = 'teamsHostClientType';

@Injectable({
  providedIn: 'root',
})
export class CloudAuthenticationService {
  public auth: CloudAuth;
  public accessToken = '';
  public principalId = '';
  public extensionId = '';
  public accountId = '';
  private cloudAuthConst;
  private refreshTimeout;
  public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private redirectPage = '#/clauthpopup';
  public appInfo;
  public authWin: any;
  public isDev: boolean;

  constructor(
    @Inject(MSTEAMS) public msTeams: typeof microsoftTeams,
    private translate: TranslateService
    ) {
      this.initClAuth();
  }

  private initClAuth(): void  {
    const appName = APP_NAME_INITIALS + '-' + ( localStorage.getItem(HOST_CLIENT_TYPE) || 'web'); // make default to web
    Config.appInfo =  new AppInfo({
      correlationIdPrefix: appName,
      app: {
        name: appName,
        version: VersionInfo.VERSION.appVersion
      },
      enableAppHeader: true
    });

    if (environment.cloudlink.isDev) {
      this.isDev = true;
      Config.cloud = 'dev';
    } else {
      this.isDev = false;
      Config.cloud = 'public';
    }

    this.cloudAuthConst = {
      clientId: environment.cloudlink.clientId,
      scope: 'abc',
      grant_type: 'authorization_code',
    };
    this.auth = new CloudAuth({ url: environment.cloudlink.tokenUrl });
  }

  public initMSTeams(): Promise<void> {
    if (this.msTeams.app.isInitialized()) {
      return Promise.resolve();
    }
    return this.msTeams.app.initialize();
  }

  public get getCloudAuthConst(): { clientId: string; scope: string; grant_type: string } {
    return this.cloudAuthConst;
  }

  public getAuthToken(authCode: string): Promise<Token> {
    const code = {
      code: encodeURI(authCode),
      clientId: this.getCloudAuthConst.clientId,
    };
    return this.auth.login({ code });
  }

  public logout(): void {
    localStorage.removeItem(MITEL_CUST_INFO);
    LoggingService.info(`Logging out of Cloudlink.`);
    this.auth.logout().then((res) => {
      this.isLoggedIn.next(false);
    }, () => {
      this.isLoggedIn.next(false);
    });
  }

  public loggedInUser(): Promise<string> {
    return this.auth
      .getToken()
      .then((token) => {
        if (token) {
          this.auth.whoAmI(token).then((claims: any) =>
            {
              this.extensionId = claims.extension;
              this.principalId = claims.principalId;
              this.accountId = claims.accountId;
              localStorage.setItem(MITEL_CUST_CL_ACC_ID, claims.accountId);
            });
          Promise.resolve();
        } else {
          return Promise.reject('Not logged in');
        }
      })
      .catch((error) => {
        LoggingService.error(`Login failed: [${error}]`);
        return Promise.reject('Not logged in');
      });
  }

  public async refreshToken(token?: Token | null, autoLogin = false): Promise<any> {
    try { // Check for the auth token. If its not there, then the user is probably loggedout
      LoggingService.info('[CloudAuthenticationService.RefreshToken]:checking for token before getToken', token, autoLogin);
      token = token ? token : await this.auth.getToken();
      LoggingService.info('[CloudAuthenticationService.RefreshToken]:checking for token after getToken', token);
      if ( ! ( token && token.refresh_token)) {
        throw new Error('[CloudAuthenticationService.RefreshToken]: Unable to obtain the token from auth');
      }
    } catch (err) {
      LoggingService.error('[CloudAuthenticationService.RefreshToken]:Token is missing', err);
      this.logout();
      if (autoLogin) {
        this.cloudLinklogin();
      }
      return Promise.resolve(null);
    }

    try {
      const refreshToken = await this.auth.refresh(token);
      await this.updateRefreshTokenTimer();
      LoggingService.info('[CloudAuthenticationService.RefreshToken]: Updating the token through refresh token' + ' ' + new Date() );
      return Promise.resolve(refreshToken);
    } catch (err) { // If anything goes wrong with the refresh, simply logout the user
        // But before that just check whether system is online or not
        if ( !window.navigator.onLine) {
          // Register an event listener so that when the window is online it will reload the page to get new token.
          window.addEventListener('online', () => {
            LoggingService.info('Window is back online. Reloading the window to get new token.');
            window.location.reload();
          });
          return;
        }
        LoggingService.error('[CloudAuthenticationService.RefreshToken]:Something went wrong. Prompting the user to login again', err);
        this.logout(); // reset everything
        if (autoLogin) {
          this.cloudLinklogin();
        }
      }
    }

  public hasToken(): Promise<boolean> {
    return this.auth.hasToken();
  }

  public whoAmI(): Promise<Claims> {
    return this.auth.whoAmI();
  }

  public async resolveAuthToken(authCode: string): Promise<any> {
    try {
      if (!authCode) {
        throw new Error('Auth Code Empty.');
      }
      const tokenResp = await this.getAuthToken(authCode);
      LoggingService.info('[CloudAuthenticationService.resolveAuthToken] retrieving tokenResp', tokenResp);
      if (tokenResp) {
        const newRefToken = await this.refreshToken();
        if ( newRefToken && newRefToken.access_token ) {
          // set user principalId
          const claim = await this.auth.whoAmI(tokenResp);
          this.principalId = claim.principalId;
          this.isLoggedIn.next(true);
          LoggingService.info('[CloudAuthenticationService.RefreshToken]: Setting the Login to true');
        }
        return Promise.resolve(true);
      }
    } catch (e) {
      LoggingService.error('Something went wrong', e);
      this.isLoggedIn.next(false);
      return Promise.reject(e);
    }
  }

  public async cloudLinklogin(): Promise <void> {
    let authResponse;
    try {
      await this.initMSTeams();
    } catch (err) {
      console.log('Something went wrong during the initialization of auth', err);
    }
    try {
      if ( this.msTeams.app.isInitialized()) {
        authResponse = await this.msTeams.authentication.authenticate({
          url: location.origin + location.pathname + this.redirectPage, // CLUrl
          width: 450,
          height: 700,
        });
      } else {
        authResponse = await AppUtils.initNativeAuthWindow(this.authWin, location.origin + location.pathname + this.redirectPage);
      }
      if (authResponse) {
        LoggingService.info('[CloudAuthenticationService]:Authcode received. Resolving the authcode for token');
        this.resolveAuthToken(authResponse);
        return Promise.resolve();
      } else {
        LoggingService.info('[CloudAuthenticationService]:Authcode not received. Returning to caller');
        return Promise.reject(null);
      }
    } catch (reason) {
      LoggingService.error('[CloudAuthenticationService]:Authentication failed ', reason);
      // In case of direct use in web,someties authentication success returns failure callback.
      // Cross check if the userlogin is valid or not
      try{
        const token = await this.getCLToken();
        const refreshToken = await this.refreshToken(token);
        if ( refreshToken && refreshToken.access_token) {
          this.isLoggedIn.next(true);
          LoggingService.info('[CloudAuthenticationService]:Reauthentication Success. Setting the login to true ');
        }
      } catch (err) {
        LoggingService.error('[CloudAuthenticationService]:Token Authentication failed ', err);
      }
    }
  }

  public closeAuthWindow(): void {
    this.authWin.close();
    this.authWin = null;
    window.onmessage = null;
  }

  public buildCloudLinkAuthUrl(): string {
    if (environment) {
      const cloudlinkUrl = environment.cloudlink.authUrl;
      const appName = 'app_name=Mitel Assistant';
      const locale = `ui_locales=${this.translate.currentLang.substr(0, 2)}`;
      const scope = 'scope=';
      const clientId = `client_id=${environment.cloudlink.clientId}`;
      const responseType = 'response_type=code';
      const queryParam = location.search.includes('?undefined') ? '' : location.search;
      const currentUrl = location.origin + location.pathname + queryParam + this.redirectPage;
      const redirectUri = `redirect_uri=${encodeURIComponent(currentUrl)}`;
      const loginHint = `login_hint=${localStorage.getItem(MITEL_CUST) || ''}`;
      const result = `${cloudlinkUrl}?${appName}&${locale}&${scope}&${clientId}&${responseType}&${loginHint}&${redirectUri}`;
      return result;
    } else {
      return null;
    }
  }

  public async validateLogin(autoLogin: boolean = true): Promise<boolean | null> {
    LoggingService.info('[Cloud-Authentication.validateLogin]: autoLogin value', autoLogin);
    return this.hasToken().then(async (isValid) => {
      if (isValid) {
        try {
          const loggedInUsr = await this.loggedInUser();
          LoggingService.info('[Cloud-Authentication.validateLogin]: checking loggedInUsr', loggedInUsr);
          if (loggedInUsr) {
            const refreshToken = await this.refreshToken();
            if (refreshToken) {
              this.isLoggedIn.next(true);
              LoggingService.info('[Cloud-Authentication.Service]: Setting the Login to true');
            }
            return Promise.resolve(true);
          } else {
            const refreshToken = await this.refreshToken(null, autoLogin);
            if ( refreshToken) { // If we are able to get the refresh token and access token then the user is logged in
              this.isLoggedIn.next(true);
              LoggingService.info('[Cloud-Authentication.Service]: loggedInUser is value false');
            }
            return Promise.resolve(null);
          }
        } catch (e) {
          LoggingService.error('[Cloud-Authentication.Service]: Something went wrong. Trying to refresh the token');
          const refreshToken = await this.refreshToken(null, autoLogin = false);
          if (refreshToken) {
            this.isLoggedIn.next(true);
            LoggingService.info('[Cloud-Authentication.Service]: refresh token successful');
          }
          return Promise.resolve(null);
        }
      } else {
        LoggingService.info('[Cloud-Authentication.validatelogin]: token is invalid');
        const refreshToken = await this.refreshToken(null, autoLogin);
        if ( refreshToken) {
          this.isLoggedIn.next(true);
          LoggingService.info('[Cloud-Authentication.Service]: isValid is value false and token refresh is successful');
        }
        return Promise.resolve(null);
      }
    });
  }

  public async getCLToken(): Promise<Token> {
    try {
      const clToken: Token = await this.auth.getToken();
      if (clToken !== null) {
        this.auth.whoAmI(clToken).then((claims: any) => {
          this.principalId = claims.principalId;
          this.extensionId = claims.extension;
          this.accountId = claims.accountId;
          localStorage.setItem(MITEL_CUST, claims.email);
          localStorage.setItem(MITEL_CUST_CL_ACC_ID, claims.accountId);
          localStorage.setItem(MITEL_CUST_INFO, btoa(JSON.stringify(claims)));
          LoggingService.info('[Cloud-Authentication.Service] Customer Info Obtained');
        });
        return Promise.resolve(clToken);
      }
    } catch (e) {
      LoggingService.error('[Cloud-Authentication.Service]: Something went wrong while fetchig the CL Auth Token', e);
    }
  }

  public async updateRefreshTokenTimer(): Promise<void> {
    try {
      const token: Token = await this.getCLToken();
      this.accessToken = token.access_token;
      const expT = Number(token.expires_in) * 1000;
      const diffT = expT;
      LoggingService.info('[Cloud-Authentication.Service]: Setting the access token after the refresh');
      clearTimeout(this.refreshTimeout);
      if (diffT < 7200000 && diffT > 0) {
        const refreshT = diffT - (300000 + Math.random() * 18000);
        this.refreshTimeout = setTimeout(this.refreshToken.bind(this, token), refreshT);
        LoggingService.info('[Cloud-Authentication.Service]: Refresh Timer Set For ' + refreshT / 1000 + 'sec');
      } else {
        this.refreshTimeout = setTimeout(this.refreshToken.bind(this, token));
        LoggingService.info('[Cloud-Authentication.Service]: Refresh Timer Set For 0 sec');
      }
    } catch (e) {
      LoggingService.error(
        '[Cloud-Authentication.Service]: Something went wrong while setting the refresh timer. Setting it again',
        this.updateRefreshTokenTimer()
      );
    }
  }

  public getCurrentUser(): string {
    return localStorage.getItem(MITEL_CUST);
  }

  async getAppInfo(): Promise<any> {
    if (! this.appInfo) {
      this.appInfo =  this.retrieveLinkVersion( await this.auth.getApplicationByClientId({
        clientId: environment.cloudlink.clientId,
        options: {
          headers: {
            'Content-Type': 'application/json',
            authorization: 'Bearer ' + this.accessToken,
          }
        }
      }));
    }
  }
  retrieveLinkVersion(appInfo): string {
    const legal = appInfo?.links?.filter(x => x.name === 'Legal');
    return legal?.[0];
  }
}
