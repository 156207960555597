<div class="main-container">
  <ng-container *ngIf="!isMobile; else closeMobile">
    <button class="close" mat-button mat-dialog-close></button>
  </ng-container>    
  <ng-template #closeMobile>
    <button class="close" mat-button (click)="closeReportIssue()">
    </button>
  </ng-template>
  <mi-report-issue
  *ngIf="isReady"
  (whenProcessingComplete)="onFormProcessComplete($event)"
  [logs]="logs"
  [followUpTextKey]="'toProgressThisIssue'"
  (onError)="error($event)"
  [appName]="appName"
  [feedbackLink]="feedbackUrl"
  [topics]="topics"
  [selectedTopic]="issueTopic"
  [callId]="callId"
  [reportTitle]="title"
  >
  </mi-report-issue>
</div>