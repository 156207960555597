import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportIssueTopic } from '@mitel-internal/cloudlink-components-catalogue';
import { LoggingService } from '../../services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../services/alert.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-issue-report',
  templateUrl: './issue-report.component.html',
  styleUrls: ['./issue-report.component.less'],
})
export class IssueReportComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    protected cdRef: ChangeDetectorRef,
    private alertService: AlertService,
    private analytics: AnalyticsService
  ) {}
  logs = LoggingService.getLogs();
  appName = 'mitel-assistant';
  // copied it from mitel one, kept the category as others
  feedbackUrl = 'https://mitel.uservoice.com/forums/931507-end-user-feedback?category_id=407133';
  get isReady(): boolean {
    return true;
  }
  topics: ReportIssueTopic = {
    title: this.translate.instant('reportIssue.whatIsThisProblemRelatedTo'),
    items: [
      { topic: 'Call History', translated: this.translate.instant('reportIssue.call-history') },
      { topic: 'Speed Dial', translated: this.translate.instant('reportIssue.speed-dial') },
      { topic: 'Presence', translated: this.translate.instant('reportIssue.presence') },
      { topic: 'Other', translated: this.translate.instant('reportIssue.other') },
    ],
  };

  @Input() issueTopic: string | null = '';
  @Input() callId: string | null = null;
  @Input() title: string | null = '';
  @Input() isMobile = false;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.cdRef.detectChanges();
  }

  onFormProcessComplete(success: boolean): void {
    if (!success) {
      this.alertService.error(this.translate.instant('reportIssue.reportSendFailure'), {
        duration: 10 * 1000,
        positionClass: 'toast-top-center-overlay',
      });
      this.analytics.record('report-issue-send-failure');
    } else {
      this.alertService.success(this.translate.instant('reportIssue.issueSendSuccess'), {
        duration: 10 * 1000,
        positionClass: 'toast-top-center-overlay',
      });
      this.analytics.record('report-issue-send-success');
    }
  }

  error($event: any): void {
    LoggingService.error('[report-issue.component] - Error in report issue form', $event);
  }

  closeReportIssue(): void {
    this.closeDialog.emit();
  }
}
