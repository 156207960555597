import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LogLevel } from '../enums/log-level';
import { LogType } from '../enums/log-type';
import { Log } from '../model/log';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  // default for debug for now
  private static level: LogLevel = LogLevel.Debug;
  private static logs: Log[] = [];

  static check401$: Subject<void> = new Subject();

  public get level(): LogLevel {
    return LoggingService.level;
  }

  public set level(lvl: LogLevel) {
    LoggingService.level = lvl;
  }

  private static addLog(log: Log, level: LogLevel, logToConsole: boolean = true): void {
    try {
      const printLog = JSON.stringify(log).replace(/\\/g, '');

      if (logToConsole) {
        if (level === LogLevel.Error) {
          console.error(printLog);
        } else if (level === LogLevel.Warning) {
          console.warn(printLog);
        } else if (level === LogLevel.Debug) {
          if (log?.data?.channel && log?.data?.message) {
            console.debug('Channel: ', log.data.channel, ' Timestamp: ', log.data.timestamp);
            console.debug(log.data.message);
          } else {
            console.debug(printLog);
          }
        } else {
          console.log(printLog);
        }
      }

      // control the log size
      while (this.logs.length > 3000) {
        this.logs.shift();
      }
    } catch (error) {
      console.warn('[logging.service.addLog]: Unable to Parse:', error);
      log.data = error.toString();
    }

    this.logs.push(log);
  }

  public static error(message: string, data?: any, logToConsole: boolean = true): void {
    if (this.level < LogLevel.Error) {
      return;
    }
    const log = new Log(LogType.Error, message, data);
    this.addLog(log, LogLevel.Error, logToConsole);
  }

  public static warning(message: string, data?: any, logToConsole: boolean = true): void {
    if (this.level < LogLevel.Warning) {
      return;
    }

    const log = new Log(LogType.Warning, message, data);
    this.addLog(log, LogLevel.Warning, logToConsole);
  }

  public static info(message: string, data?: any, logToConsole: boolean = true): void {
    if (this.level < LogLevel.Info) {
      return;
    }
    const log = new Log(LogType.Info, message, data);
    this.addLog(log, LogLevel.Info, logToConsole);
  }

  public static debug(message: string, data?: any, logToConsole: boolean = true): void {
    if (this.level < LogLevel.Debug) {
      return;
    }
    const log = new Log(LogType.Debug, message, data);
    this.addLog(log, LogLevel.Debug, logToConsole);
  }

  public static getLogs(): Log[] {
    return this.logs;
  }

  public clearLogs(): void {
    // required for test
    LoggingService.logs = [];
  }
}
