import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as microsoftTeams from '@microsoft/teams-js';
import { MSTEAMS } from '../common/constants';
import { AuthState } from '../model/auth-state';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class TeamsAuthService {
  authState: AuthState;
  window: Window;

  constructor(@Inject(DOCUMENT) private document: Document, @Inject(MSTEAMS) public msTeams: typeof microsoftTeams) {
    this.window = this.document.defaultView;
  }

  getUsername(): string {
    return this.authState.username;
  }

  resetAuthState(): void {
    LoggingService.info('[TeamsAuthService.resetAuthState]: clearing local storage and refreshing the app');
    this.authState = null;
    this.window.location.reload();
  }

  private initMSTeams(): Promise<void> {
    if (this.msTeams.app.isInitialized()) {
      return Promise.resolve();
    }
    return this.msTeams.app.initialize();
  }

  getAccessTokenWithExpiry(scopes: string[]): Promise<AuthState> {
    if ( this.msTeams.app.isInitialized()) {
      LoggingService.info('[TeamsAuthService.getAccessTokenWithExpiry]: opening auth page');
      return new Promise((resolve, reject) => {
        this.msTeams.authentication.authenticate({
          url: window.location.origin + '/#/teamsauthpopup',
          width: 600,
          height: 535,
        }).then(
          (response) => {
            try {
              const resp = typeof response === 'string' ? JSON.parse(response) : response;
              const userAgent = window.navigator.userAgent.toLowerCase();
              // Check if Teams is running in desktop app
              if (!userAgent.includes('teams')) {
                for (const key of Object.keys(resp.localStorageFromPopupWindow)) {
                  localStorage.setItem(key, resp.localStorageFromPopupWindow[key]);
                }
              }
              // UI issue in Android.
              // Cross verify if the response is in object format already. Incase it is no need to parse it again.
              const [ username, accessToken, expiresOn ] = [resp?.username, resp?.accessToken, resp?.expiresOn];
              this.authState = new AuthState({ username, accessToken, expiresOn });
              resolve(this.authState);
            } catch (e) {
              LoggingService.error(
                '[TeamsAuthService.getAccessTokenWithExpiry]: Error occurred in parsing the response. Error: ',
                e
              );
              reject('Error occurred in parsing the response');
            }
          },
          (reason) => {
            LoggingService.error('[TeamsAuthService.getAccessTokenWithExpiry]: failed to login to Miscrosoft: ', reason);
            reject(reason);
          },
        );
      });
    } else {
      this.msTeams.app.initialize();
      return Promise.reject('MS Teams app was not initialised');
    }
  }
}
