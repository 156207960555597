export const colors = {
  // ONLY USED IN MOWA
  primaryHover: '#0085f0',
  deleteButtonGradientTop: '#ff5a55',
  deleteButtonGradientBottom: '#eb3530',
  callStatusBackground: '#2494ef',
  callStatusHover: '#0d5691',
  callStatusMuted: '#f26065',
  callStatusHolding: '#f8ca00',
  callScreenHangupGradientTop: '#f85359',
  callScreenHangupGradientBottom: '#dc151d',
  callScreenCallGradientTop: '#39b54a',
  callScreenCallGradientBottom: '#34aa44',

  // SHOULD BE IN PALLET?
  tangerine: '#fdce84',
  primaryButtonGradientTop: '#0080e7',
  tableHover: '#e5f5fc',
  mitelDarkBlue: '#15325f',
  toggleColorGradientStart: '#a3adb7',
  toggleColorGradientEnd: '#becad6',
  lightWidgetBorderGradientStart: '#FEFEFF',
  lightWidgetBorderGradientEnd: '#C6CEDA',
  darkWidgetBorderGradientStart: '#0080E7',
  oneAppHover: '86, 183, 253',
  oneAppPillButton: '#67778F',
  tabBarFocus: '#BDD9F1',

  // NEW COLOR PALLET START HERE
  oneAppDarkBlue: '#2354A0',
  oneAppBlue500: '#0073D0',
  oneAppBlueBg: '#AFD7E7',
  oneAppDarkPurple: '#4232D1',
  oneAppPurple: '#6659D7',
  oneAppPurpleBg: '#C2BBFE',
  onrAppDarkTeal: '#0E6158',
  oneAppTeal: '#12B886',
  oneAppTealBg: '#6DECE0',
  oneAppDarkGreen: '#057C10',
  oneAppGreen: '#3FAC49',
  oneAppGreenBg1: '#9ADAA0',
  oneAppGreenBg2: '#BAFFAE',
  oneAppDarkRed700: '#A4221E',
  oneAppRed600: '#CD2B26',
  oneAppRedBg: '#F59A98',
  oneAppDarkPink: '#BD0071',
  oneAppPink: '#D24887',
  oneAppPinkBg: '#FFD0E7',
  oneAppDarkOrange: '#C24501',
  oneAppOrange: '#FF7900',
  oneAppOrangeBg: '#FCC07A',
  oneAppYellow: '#FDB913',
  oneAppYellowBg: '#FFF2E3',
  oneAppBlack: '#000000',
  oneAppInk900: '#1F1F1F',
  oneAppInk800: '#464B51',
  oneAppInk700: '#595E64',
  oneAppInk600: '#777E85',
  oneAppInk500: '#989DA3',
  oneAppGreyHdr: '#ACB3B9',
  oneAppGreyBg: '#D1D6DB',
  oneAppGrey: '#EBEFF3',
  oneAppWhiteAlt: '#F0F2F4',
  oneAppWhiteBg: '#F8F9FB',
  oneAppWhite: '#FFFFFF',
  // NEW COLOR PALLET END HERE
};
