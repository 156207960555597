import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import * as microsoftTeams from '@microsoft/teams-js';
import { DOCUMENT } from '@angular/common';
import { MSTEAMS } from '../common/constants';
import { TeamsAuthService } from '../services/teams-auth.service';
import { ComponentLoginUrlMap } from '../helpers/loginghelper-component-map';
import { TeamsContextService } from '../services/teams-context.service';
import { CloudAuthenticationService } from '../services/cloud-authentication.service';
import { LoggingService } from '../services/logging.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  window: Window;
  constructor(
    public router: Router,
    public authService: AuthService,
    public teamsAuthService: TeamsAuthService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MSTEAMS) public msTeams: typeof microsoftTeams,
    private teamsContextService: TeamsContextService,
    private clAuth: CloudAuthenticationService
  ) {
    this.window = this.document.defaultView;
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    LoggingService.info(`[AuthGuard.canActivate]: activated route: ${route}`);

    LoggingService.info(`[AuthGuard.canActivate]: Checking if the user is already logged in`);
    const isLoggedIn = await this.authService.isLoggedIn();

    this.reloadOnUserChange();

    if (isLoggedIn) {
      LoggingService.info(`[AuthGuard.canActivate]: user is already logged in`);
      return true;
    }

    const url = ComponentLoginUrlMap.getLoginUrl(route.url[0].path);
    LoggingService.info(
      `[AuthGuard.canActivate]: User is not logged in, reditecting to give permission screen : ${url}`
    );
    this.router.navigate([url]);
    return false;
  }

  private async reloadOnUserChange(): Promise<void> {
    LoggingService.info(`[AuthGuard.reloadOnUserChange]: Checking if a different user is logged in`);
    const context = await this.teamsContextService.getContext();
    if (!context) {
      return;
    }

    const userName = this.authService.getUsername();
    if (userName && context?.user?.userPrincipalName !== userName) {
      LoggingService.info(
        `[AuthGuard.reloadOnUserChange]: Different user is detected, signing out and reloading application.`
      );

      this.clAuth.logout();
      localStorage.clear();
      this.teamsAuthService.resetAuthState();
    } else {
      LoggingService.info(`[AuthGuard.reloadOnUserChange]: No logged in user found`);
    }
  }
}
