import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], prop: string, value: string): unknown { // loginFilter: {currentState: string, propName: string} | null
    return items.sort((a: any, b: any) => a?.order - b?.order ).filter((item) => {
      console.log(prop, ' value ', item[prop], 'expexted value', value );
      if ( Array.isArray(item[prop])) {
        return item[prop].includes(value);
      } else {
        return item[prop] === 'none' || item[prop] === value;
      }
    });
  }
}
