import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Contact } from 'src/app/model/contact';
import { ContactService } from 'src/app/services/contact.service';
import { forkJoin, interval, Subscription, timer } from 'rxjs';
import { IPhoneNumber } from 'src/app/interfaces/contact';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { TeamsContextService } from 'src/app/services/teams-context.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-conversation-member',
  templateUrl: './conversation-member.component.html',
  styleUrls: ['./conversation-member.component.less'],
})
export class ConversationMemberComponent implements OnInit, OnDestroy {
  contact: Contact;
  error = '';
  window: Window;
  message = '';
  showAllPhones = false;
  timerPresenceUpdate: Subscription;

  constructor(
    private contactService: ContactService,
    @Inject(DOCUMENT) private document: Document,
    private teamsContextService: TeamsContextService,
    private translate: TranslateService,
    private anlytics: AnalyticsService
  ) {
    this.window = this.document.defaultView;
  }

  async ngOnInit(): Promise<void> {
    try {
      const context = await this.teamsContextService.getContext();
      console.log('Context is ', context);
      if (context) {
        LoggingService.info('[ConversationMemberComponent] MS Teams channel context: ' + JSON.stringify(context));
        if (!!context?.team?.internalId) {
          this.translate.get('message.teams').subscribe((text: string) => {
            this.message = text;
          });
          return;
        } else if (!context?.chat?.id && !context?.team?.internalId) {
          this.translate.get('message.teams').subscribe((text: string) => {
            this.message = text;
          });
          return;
        }
        await this.getContactDetails(context);
      }
    } catch (error) {
      this.error = error.message;
    }
  }

  get showContactInfo(): boolean {
    return !!this.contact && !this.message;
  }

  get showLoader(): boolean {
    return !this.contact && !this.message;
  }

  get showMessage(): boolean {
    return (!!this.contact && !!this.message) || (!this.contact && !!this.message);
  }

  togglePhoneList(): void {
    this.showAllPhones = !this.showAllPhones;
  }

  selectPhoneNumber(contact: Contact, phone: IPhoneNumber): void {
    contact.selectPhoneNumber(phone.number);
    this.showAllPhones = false;
  }

  private async getContactDetails(context: any): Promise<void> {
    let recepientId = '';
    return forkJoin([this.contactService.getMyDetails(), this.contactService.getConversationMembers(context?.chat?.id)])
      .toPromise()
      .then(
        async ([user, members]) => {
          if (members.length > 2) {
            this.translate.get('message.group').subscribe((text: string) => {
              this.message = text;
            });
            return;
          }
          recepientId = members.find((m) => m.userId !== user.id).userId;
          const contact = await this.contactService.getContact(recepientId);
          this.contact = contact;

          await this.updateContactPhones(contact);
          await this.updateContactPhoto(contact);
          await this.updateContactPresence(contact);
        },
        (error) => {
          this.error = error.message;
          if (!!context?.chat?.id && !context?.team?.internalId && this.error === 'Not Found') {
            this.translate.get('message.fresh-coversation').subscribe((text: string) => {
              this.message = text;
            });
          }
        }
      );
  }

  private async updateContactPhoto(contact: Contact): Promise<Contact> {
    const contactPhoto = await this.contactService.getContactPhoto(contact.id);
    return contact.updatePhoto(contactPhoto);
  }

  private async updateContactPhones(contact: Contact): Promise<Contact> {
    const contactPhone = await this.contactService.getContactPhones(contact.id);
    return contact.updatePhones(contactPhone);
  }

  private async updateContactPresence(contact: Contact): Promise<Contact> {
    const contactPresence = await this.contactService.getContactPresence(contact.id);
    this.timerPresenceUpdate = timer(8000).subscribe(() => this.updateContactPresence(contact));
    return contact.updatePresence(contactPresence);
  }

  dialHandler(): void {
    this.anlytics.record('dialed-from-chat');
  }

  closeMessage(): void {
    if (this.teamsContextService) {
      this.teamsContextService.submitTask();
    }
  }

  ngOnDestroy(): void {
    this.timerPresenceUpdate.unsubscribe();
  }
}
