<div *ngIf="contact" class="container">
  <div class="card">
    <div class="details">
      <div *ngIf="contact.photo" class="user-photo">
        <div class="user-photo user-photo-backgrnd" [ngStyle]="{ 'background-image': contact?.photo }"></div>
        <div presenceStatus [presence]="contact?.presence" [activity]="contact?.activity" class="presence presence-contact-photo"></div>
      </div>
      <div randomColor *ngIf="!contact.photo" class="user-photo">
        <div class="avatar">{{ contact.initials }}</div>
        <div presenceStatus [presence]="contact?.presence" [activity]="contact?.activity" class="presence"></div>
      </div>
      <div class="user-name">{{ contact.displayName }}</div>
      <span class="address">
        <span class="user-phone">
          {{ contact.selectedPhoneNumber }}
        </span>
        <div *ngIf="contact.hasMoreThanOnePhoneNumbers" class="dropdown-icon" (click)="togglePhoneList()"></div>
      </span>
      <div *ngIf="showAllPhones" class="dropdown">
        <app-phone-number-list [contact]="contact" (selected)="phoneNumberSelected()"></app-phone-number-list>
      </div>
    </div>
    <div
      class="call call-backgrnd"
      [ngClass]="{ 'disable-call': !contact.hasPhoneNumber }"
      (click)="dialHandler(contact.selectedPhoneNumber)"
    ></div>
  </div>
</div>
