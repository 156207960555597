import { Injectable } from '@angular/core';
import { CloudAuthenticationService } from './cloud-authentication.service';
import { LoggingService } from './logging.service';
import { FeatureTeachers, TagKeys } from '../interfaces/featureTeachers';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { AdminService, Config } from '@mitel/cloudlink-sdk';

@Injectable({
  providedIn: 'root'
})
export class EulaService {

  msTeamsEULATag: any;
  private eulaAccpeted$: BehaviorSubject<boolean | null> =  new BehaviorSubject(null);
  featureTeacherLastUpdated: any;
  public adminService: AdminService;

  constructor(
    private clAuth: CloudAuthenticationService
  ) {
    this.adminService = new AdminService({ url: environment.cloudlink.adminUrl, http: Config.http });
   }


  get eulaAccepted(): BehaviorSubject<boolean> {
    return this.eulaAccpeted$;
  }

  get optionsHeader(): any {
   return  {
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + this.clAuth.accessToken,
      }
    };
  }


  updateEulaStatus(value: boolean | null): void {
    this.eulaAccpeted$.next(value);
  }
  public async validateEULA(): Promise<any> {
    await this.clAuth.getAppInfo();
    try {
      const featureTeacher: FeatureTeachers  =  await this.getUserTag(TagKeys.featureTeachers);
      this.featureTeacherLastUpdated =  featureTeacher;

      if ( featureTeacher.mitelAssistantEULA) {
        const isAccepted =  this.checkLatestEULAAccepted( this.clAuth.appInfo, featureTeacher.mitelAssistantEULA);
        if ( isAccepted ) {
          LoggingService.info('[EulaService.ValidateEULA] Eula Accepted', isAccepted);
          this.updateEulaStatus(isAccepted);
          this.msTeamsEULATag = featureTeacher.mitelAssistantEULA;
        } else {
          this.msTeamsEULATag = null;
          this.updateEulaStatus(isAccepted || false);
        }
      } else {
          try {
            const userTagResp = await this.getUserTag('t_and_c_' + environment.cloudlink.clientId);
            const isAccepted = this.checkLatestEULAAccepted(this.clAuth.appInfo, userTagResp);
            if ( isAccepted) {
              const resp = await this.saveAcceptanceConfirmation(userTagResp);
              this.msTeamsEULATag = resp?.mitelAssistantEULA;
            }
            this.updateEulaStatus(isAccepted);
            Promise.resolve(true);
          } catch (err) {
            LoggingService.error('[EulaService.validateEula]: API failure in getUser by tag t_and_c_.');
            this.msTeamsEULATag = null;
            this.updateEulaStatus(false);
            Promise.resolve(null);
          }
      }
    } catch (err) {
      Promise.resolve(null);
      this.updateEulaStatus(null);
      this.msTeamsEULATag = null;
      LoggingService.error('[EulaService.validateEula] Get User By Tag operation failed', err);
    }
  }

  checkLatestEULAAccepted(appInfo, tagInfo): boolean {
    const isAccepted = !!(appInfo && tagInfo && appInfo?.url === tagInfo?.url);
    return isAccepted;
  }

  saveAcceptanceConfirmation(tagResponse = null): Promise<any> {
    this.msTeamsEULATag = {
      url: this.clAuth.appInfo.url,
      version: this.clAuth.appInfo?.version,
      date: tagResponse?.date || Math.floor(Date.now() / 1000) // Just taking the time till secs
    };
    return this.addFeatureTeacherTag('mitelAssistantEULA', this.msTeamsEULATag);
  }

  getUserTag(tagId: string): Promise<any> {
    return this.adminService.getUserTag({
      userId: this.clAuth.principalId,
      tagId,
      options: this.optionsHeader
    });
  }

  addFeatureTeacherTag(key: any, value: any = true ): Promise<any> {
    const tag = {};
    tag[TagKeys.featureTeachers] = this.featureTeacherLastUpdated;
    const create = Object.keys(tag[TagKeys.featureTeachers]).length === 0;
    if (!tag[TagKeys.featureTeachers][key] || tag[TagKeys.featureTeachers][key] !== value) {
      LoggingService.info('[EulaService.addFeatureTeacherTag()]', [key, value, create]);
      tag[TagKeys.featureTeachers][key] = value;
      this.featureTeacherLastUpdated = tag[TagKeys.featureTeachers];
      if (create) {
        return this.createUserTag(this.clAuth.principalId, JSON.stringify(tag));
      } else {
        return this.updateUserTag(this.clAuth.principalId, TagKeys.featureTeachers, JSON.stringify(tag[TagKeys.featureTeachers]));
      }
    }
    return Promise.resolve(null);
  }

  private createUserTag(userId: string, body: any): Promise<any> {
    LoggingService.info('[EulaService.service.createUserTag()]', {
      userId,
      body
    });
    return this.adminService.createUserTag({
        userId,
        body,
        options: this.optionsHeader
      });
  }

  private updateUserTag(userId: string, tagId: string, body: any): Promise<any> {
    LoggingService.info('[EulaService.updateUserTag()]', {
      userId,
      body
    });
    return this.adminService.updateUserTag({
      userId,
      tagId,
      body,
      options: this.optionsHeader
    });
  }

}
