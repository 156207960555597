
<div class="main-container" *ngIf="!isMobileView">
  <nav class="top-nav-bar">
    <app-contact-list
      class="search-box-position"
      [placeHolderText]="searchPlaceHolderText"
      [action]="'dial'"
      [searchText]="searchText"
      (selected)="dialHandler($event)"
      (digits)="updateSearchText($event)">
    </app-contact-list>
    <div class="sub-header-wrapper">
      <button class="hamburger"  (click)="showSettingsDialog($event)">
        <img src="assets/menu-hamburger.svg" />
       
      </button>
      <ng-container *ngIf="showSettings; then popupSettings"></ng-container>
    </div>
  </nav>
  
  <div class="columns-container" #columnsContainer>
    <div *ngFor="let item of subHeaders | filter : 'view' : 'desktop' | filter : 'isClRequired': isClLoggedIn | filter : 'hideAtSmallScreen' : (isMidSizedScreen && isClLoggedIn ? false : true)" class="columns-extendable" [attr.data-msassitant-tab]= "item.value">
      <div class="container-body"><!-- This will mount the components-->
        <div class="main-body">
          <ng-container [ngSwitch]="item.value">
            <div *ngSwitchCase="'dialPad'" class="dialpad-container">
              <app-contact-list
                class="search-box-position"
                [placeHolderText]="searchPlaceHolderText"
                [action]="'dial'"
                [searchText]="searchText"
                (selected)="dialHandler($event)"
                (digits)="updateSearchText($event)"
              ></app-contact-list>
              <app-dialer class="dialer-position" [dialedNumber]="searchText" (digits)="updateSearchText($event)"></app-dialer>
            </div>
  
            <div *ngSwitchCase="'callHistory'" class="history-container">
              <ng-container *ngIf="isMidSizedScreen; then speedDials" ></ng-container>
              <app-call-history [isVoicemailWaitingIndicator]="isVoicemailWaitingIndicator" #callHistory class="call-history-component"></app-call-history>
            </div>
            
            <div *ngSwitchCase="'speedDials'" class="speeddials-container" [ngStyle]="{'display: none': isMidSizedScreen}">
              <ng-container *ngIf="true; then speedDials"></ng-container>
            </div>
  
          </ng-container>
        </div>
        <div [ngClass]="{'side-border-bar': !isEditMode, 'side-stretch-bar': isEditMode}">
        </div>
      </div>
      <div class="footer-stretch-bar">
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="isClLoggedIn || isMobileView; else stickyCLLogin">
</ng-container>


<ng-template #speedDials>
  <div class="speed-dials-wrapper">
    <div class="btn-wrapper">
      <button
        class="speed-dial-button"
        [disabled]="!allowAddingSpeedDialContact"
        [ngClass]="{
          'disable-add-button': !allowAddingSpeedDialContact,
          hide: isSpeedDialListEmpty,
          show: !isSpeedDialListEmpty
        }"
        (click)="showAddSpeedDial()"
      >
        +&nbsp;{{ 'speed-dial.add.button-text' | translate }}
      </button>
      <p [ngClass]="{ hide: isSpeedDialListEmpty }">{{ 'speed-dial.title' | translate }}</p>
    </div>
    <ng-conainer *ngIf="showAddressBookMsg && isSpeedDialListEmpty; then zeroSpeedDials; else speedDialList"></ng-conainer>
    
    <app-add-speed-dial *ngIf="showAddSpeedDialComponent" (add)="handleAddCancelEvent($event)"></app-add-speed-dial>
  </div>
</ng-template>
<ng-template #speedDialList>
  <app-speed-dial-list (loaded)="setSpeedDialLoading()"></app-speed-dial-list>
</ng-template>
<ng-template #zeroSpeedDials>
  <div class="zero-speed-dial-container">
    <div class="address-book"></div>
    <div class="message">{{ 'speed-dial.address-book.empty-text' | translate }}</div>
    <button class="zero-speed-dial-button" (click)="showAddSpeedDial()">
      +&nbsp;{{ 'speed-dial.add.button-text' | translate }}
    </button>
  </div>
</ng-template>
<!-- Commented out for now -->
<ng-template #stickyCLLogin>
  <div *ngIf="isCLEnabled && !flagHidePopup" class="sticky-bottom-hover-dialog">
    <div>
      <p>{{ 'login.loginbox-header' | translate }}</p>
      <span class="close" (click)="hideLoginPop()">+</span>
    </div>
    <div>
      <span><img src="" /></span>
      <span> {{ 'login.loginbox-body' | translate }} </span>
    </div>
    <div>
      <button class="btn-flat-transparent" (click)="hideLoginPop(true)">
        <input type="checkbox" id="check-hide-persistent" (click)="hideLoginPop(true)" #checkboxHidePopup>
        {{'login.dont-show-again' | translate }}
      </button>
      <button class="login-button-round" (click)="loginClLink()">{{ 'login.loginbox-button' | translate }}</button>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="showContactInfoDialog">
  <div class="contact-info-background">
    <div class="contact-info">
      <div class="close-contact-info" (click)="closeContactInfoDialog()"></div>
      <app-contact-details [contact]="selectedContact" (dialed)="closeContactInfoDialog()"></app-contact-details>
    </div>
  </div>
</ng-container>

<ng-template #popupSettings>
  <app-user-profile 
  [currentUser]="currentUser"
  [isMobile]="isMobileView"
  [isCLEnabled]="isCLEnabled"
  (reportIssueDialogEvt)="openReportIssueDialog()"
  (logOutEvt) = "logOut(true)"
  (logInEvt)="loginClLink()"
  (closeDialog)="showSettingsDialog()" 
  #elemSettings></app-user-profile>
</ng-template>

<ng-template #reportIssueMobile>
  <div [ngClass]="'settings-hover-dialog report-issue-mobile'">
    <app-issue-report [isMobile]="true" (closeDialog)="showReportIssue = false"></app-issue-report>
  </div>
</ng-template>


<!-- ####################Code for the showing the mobile section ######################## -->
<div class="main-container-mobile" *ngIf="isMobileView">
  <div class="mobile-header" *ngIf="isCLEnabled" id="header">

    <div class="avatar-container">
    </div>
    <app-contact-list
      class="search-box-position"
      [placeHolderText]="searchPlaceHolderText"
      [action]="'dial'"
      [searchText]="searchText"
      (selected)="dialHandler($event)"
      (digits)="updateSearchText($event)">
    </app-contact-list>
    <div class="option-container">
      <button [ngClass]="{'hamburger': true, 'hamburger-close' : showSettings}" (click)="showSettingsDialog()">
        <span class="thick-line"></span>
        <span class="thick-line"></span>
        <span class="thick-line"></span>
      </button>
    </div>
  </div>
  <ng-container *ngIf="showSettings; then popupSettings"></ng-container>
  <ng-container *ngIf="showReportIssue; then reportIssueMobile"></ng-container>

  <!--Section for holding dialpad and search contact list-->
  <div [ngSwitch]="currentSelectedTab" class="mobile-view-container">
    <ng-template [ngSwitchCase]="'dialPad'">
      <div class="dialer-container" >
        <div class="contact-wrapper">
          <app-contact-list
          class="search-box-position"
          [placeHolderText]="searchPlaceHolderText"
          [action]="'dial'"
          [searchText]="searchText"
          (selected)="dialHandler($event)"
          (digits)="updateSearchText($event)"
        ></app-contact-list>
        </div>
        <app-dialer [dialedNumber]="searchText" (digits)="updateSearchText($event)"></app-dialer>
      </div>
    </ng-template>

    <!-- Section for holding the speed dial section-->
    <ng-template [ngSwitchCase]="'speedDials'">
      <div class="speed-dials-wrapper" [ngClass]="{'semiheight':isClLoggedIn, 'fullheight': !isClLoggedIn}">
        <div class="btn-wrapper">
          <button
            class="speed-dial-button"
            [disabled]="!allowAddingSpeedDialContact"
            [ngClass]="{
              'disable-add-button': !allowAddingSpeedDialContact,
              hide: isSpeedDialListEmpty,
              show: !isSpeedDialListEmpty
            }"
            (click)="showAddSpeedDial()"
          >
            +&nbsp;{{ 'speed-dial.add.button-text' | translate }}
          </button>
          <p [ngClass]="{ hide: isSpeedDialListEmpty }">{{ 'speed-dial.title' | translate }}</p>
        </div>
        <ng-conainer *ngIf="showAddressBookMsg && isSpeedDialListEmpty; then zeroSpeedDials; else speedDialList"></ng-conainer>
        <app-add-speed-dial *ngIf="showAddSpeedDialComponent" (add)="handleAddCancelEvent($event)"></app-add-speed-dial>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="'callHistory'">
      <app-call-history [isVoicemailWaitingIndicator]="isVoicemailWaitingIndicator" #callHistory></app-call-history>
    </ng-template>
  </div>


  <div class="view-switcher" [ngClass]="{ 'view-no-margin': showSpeedDial }">
    <button 
    *ngFor="let item of subHeaders | filter : 'view' : 'mobile' | filter : 'isClRequired': isClLoggedIn"
    class="switch" (click)="selectedTab(item.value)">
      <span class="mobile-icons-span" [ngClass]="{'active': item.value === currentSelectedTab}">
        <img [src]="item.value === currentSelectedTab ? item.iconPathActive : item.iconPath">
        <span> {{item.name}} </span>
      </span>
    </button>
  </div>

  <ng-container *ngIf="isClLoggedIn && isMobileView && eulaRes === false" #eulaPageMobile>
    <div class="eula-background-full">
      <app-eula></app-eula>
    </div>
  </ng-container>
</div>
<!-- #################### End of Code for the showing the mobile section ######################## -->
