import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../services/language.service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { LoggingService } from '../services/logging.service';

export function HttpLoaderFactory(httpClient: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(httpClient, [
    {
      prefix: '/assets/i18n/cl-catalogue/',
      suffix: '.json' + '?cb=' + new Date().getTime(),
    },
    {
      prefix: '/assets/i18n/',
      suffix: '.json' + '?cb=' + new Date().getTime(),
    },
  ]);
}

export function telUrl(phoneNumber: any, isPbxCall: boolean = false): string {
  isPbxCall =  isPbxCall || false; // Additional check against undefined or empty value
  LoggingService.info('[Commonhelper] Launching tel url', `tel:${phoneNumber.replace(/ /g, '')}?mode=direct${isPbxCall ? '&fromPbxCallHistory=true' : ''}` );
  return `tel:${phoneNumber.replace(/ /g, '')}?mode=direct${isPbxCall ? '&fromPbxCallHistory=true' : ''}`;
}

export function telUrlAndroid(phoneNumber: any, isPbxCall: boolean = false): string {
  isPbxCall =  isPbxCall || false; // Additional check against undefined or empty value
  if (!isPbxCall){
    LoggingService.info('[Commonhelper] Launching tel url', `tel:${phoneNumber.replace(/ /g, '')}`);
    return `tel:${phoneNumber.replace(/ /g, '')}`;
  }
  LoggingService.info('[Commonhelper] Launching tel url', `tel:${phoneNumber.replace(/ /g, '')}?${isPbxCall ? '&fromPbxCallHistory=true' : ''}` );
  return `tel:${phoneNumber.replace(/ /g, '')}?${isPbxCall ? '&fromPbxCallHistory=true' : ''}`;
}

export function telUrlMicollab(phoneNumber: any, isPbxCall: boolean): string {
  isPbxCall =  isPbxCall || false; // Additional check against undefined or empty value
  LoggingService.info('[Commonhelper] Launching micollab url', `micollab://call?number=${phoneNumber.replace(/ /g, '')}&mode=direct${isPbxCall ? '&fromPbxCallHistory=true' : ''}` );
  return `micollab://call?number=${phoneNumber.replace(/ /g, '')}&mode=direct${isPbxCall ? '&fromPbxCallHistory=true' : ''}`;
}

export function isValidPhoneNumber(text = ''): boolean {
  return /^[*#]*[\d(][-()\d\*# ]*$/.test(text) || (text.startsWith('+')) || (text.startsWith('***'));
}

export function setupLang(languageService: LanguageService): () => Promise<void> {
  return () => languageService.setup();
}
