import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MSTEAMS } from '../common/constants';
import * as microsoftTeams from '@microsoft/teams-js';
import { LanguageKeys } from '../model/language-keys';
import { TeamsContextService } from './teams-context.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  window: Window;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    @Inject(MSTEAMS) public msTeams: typeof microsoftTeams,
    private teamsContextService: TeamsContextService
  ) {
    this.window = this.document.defaultView;
    this.translate.setDefaultLang('en');
  }

  public async setup(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const context = await this.teamsContextService.getContext();
      if (context) {
        LoggingService.info('[LanguageService]: Setting language');
        this.setLanguage(context);
        resolve();
      } else {
        reject();
      }
    });
  }
  public setLanguage(context: microsoftTeams.app.Context): void {
    const lang: string = context.app.locale.substr(0, 2);
    switch (lang) {
      case LanguageKeys.DE:
        this.translate.use('de-DE');
        break;
      case LanguageKeys.EN:
        this.translate.use('en');
        break;
      case LanguageKeys.ES:
        this.translate.use('es-ES');
        break;
      case LanguageKeys.FR:
        this.translate.use('fr-FR');
        break;
      case LanguageKeys.IT:
        this.translate.use('it-IT');
        break;
      case LanguageKeys.NL:
        this.translate.use('nl');
        break;
      case LanguageKeys.PT:
        this.translate.use('pt-PT');
        break;
      default:
        this.translate.use('en');

        break;
    }
  }
}
