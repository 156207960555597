import { IUser, IUserResp } from '../interfaces/user';

export class User implements IUser {
    accountId: string;
    active: boolean;
    displayName: string;
    email: string;
    extension: string;
    firstName: string;
    lastName: string;
    loginId: string;
    mobile: string;
    name: string;
    phone: string;
    policyId: string;
    role: string;
    sipAddress: string;
    siteId: string;
    uniqueUserId: string;
    userId: string;
    avatars: string;

    constructor(params: IUserResp) {
        this.accountId = params.accountId;
        this.active =  params.active;
        this.displayName = params.displayName;
        this.email = params.email;
        this.extension = params.extension;
        this.firstName = params.firstName;
        this.lastName = params.lastName;
        this.loginId = params.loginId;
        this.mobile = params.mobile;
        this.name = params.name;
        this.phone = params.phone;
        this.policyId = params.policyId;
        this.role = params.role;
        this.sipAddress = params.sipAddress;
        this.siteId = params.siteId;
        this.uniqueUserId = params.uniqueUserId;
        this.userId = params.userId;
        this.avatars = params.avatars;

    }
}
