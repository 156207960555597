import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ContactPresence, ContactActivity } from '../enums/contact-presence';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[presenceStatus]',
})
export class PresenceDirective implements OnChanges, OnDestroy {
  @Input() presence: ContactPresence;
  @Input() activity: ContactActivity;
  private timeOut: any;

  constructor(private elementRef: ElementRef, private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.presence) {
      let colour: string;
      let statusText: string;
      let statusIcon: string;
      const assetPath = '../../assets/';
      switch (this.presence) {
        case ContactPresence.Available:
        case ContactPresence.AvailableIdle: {
          colour = '#2cbf3d';
          statusText = 'presence.available';
          statusIcon = 'icon-available.svg';
          break;
        }
        case ContactPresence.Away:
          statusText = 'presence.away';
          statusIcon = 'icon-away.svg';
          break;
        case ContactPresence.BeRightBack: {
          colour = '#edc01c';
          statusText = 'presence.beRightBack';
          statusIcon = 'icon-brb.svg';
          break;
        }
        case ContactPresence.Busy:
        case ContactPresence.BusyIdle:
        case ContactPresence.DoNotDisturb: {
          colour = '#ed1c24';
          statusIcon =
            this.presence === ContactPresence.Busy
              ? 'icon-busy.svg'
              : ContactPresence.DoNotDisturb
              ? 'icon-dnd.svg'
              : 'icon-busy.svg';
          statusText =
            this.presence === ContactPresence.Busy
              ? 'presence.busy'
              : ContactPresence.DoNotDisturb
              ? 'presence.doNotDisturb'
              : 'presence.busy';
          break;
        }
        case ContactPresence.Offline:
          statusText = 'presence.offline';
          statusIcon = 'icon-presence-unknown.svg';
          break;
        case ContactPresence.PresenceUnknown: {
          colour = '#d9d9d4';
          statusText = 'presence.unknown';
          statusIcon = 'icon-unknown-status.svg';
          break;
        }
      }

      switch (this.activity) {
        case ContactActivity.InAConferenceCall:
          statusText = 'presence.inConference';
          break;
        case ContactActivity.Presenting:
          statusText = 'presence.presenting';
          break;
        case ContactActivity.InCall:
          statusText = 'presence.inCall';
          break;
        case ContactActivity.InAMeeting:
          statusText = 'presence.inMeeting';
          break;
        case ContactActivity.OutOfOffice:
          statusText = 'presence.outOffice';
          break;
      }
      this.timeOut = window.setTimeout(() => this.updateThisElement(statusText, assetPath, statusIcon), 500);
      // Added 500ms delay as the status is not updated immediately when a new speed dial contact is added
    }
  }

  updateThisElement(statusText: string, assetPath: string, statusIcon: string): void {
    statusText = statusText || 'presence.unknown';
    statusIcon = statusIcon || 'icon-unknown-status.svg';
    this.translate.get(statusText).subscribe((msg) => (this.elementRef.nativeElement.title = msg));
    this.elementRef.nativeElement.style['background-image'] = `url("${assetPath}${statusIcon}")`;
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeOut);
  }
}
