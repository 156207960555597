<div class="container">
  <div class="row">
    <div class="digit" id="one" (click)="addDigit('1')"></div>
    <div class="digit" id="two" (click)="addDigit('2')">
      <div class="sub">A B C</div>
    </div>
    <div class="digit" id="three" (click)="addDigit('3')">
      <div class="sub">D E F</div>
    </div>
  </div>
  <div class="row">
    <div class="digit" id="four" (click)="addDigit('4')">
      <div class="sub">G H I</div>
    </div>
    <div class="digit" id="five" (click)="addDigit('5')">
      <div class="sub">J K L</div>
    </div>
    <div class="digit" id="six" (click)="addDigit('6')">
      <div class="sub">M N O</div>
    </div>
  </div>
  <div class="row">
    <div class="digit" id="seven" (click)="addDigit('7')">
      <div class="sub">P Q R S</div>
    </div>
    <div class="digit" id="eight" (click)="addDigit('8')">
      <div class="sub">T U V</div>
    </div>
    <div class="digit" id="nine" (click)="addDigit('9')">
      <div class="sub">W X Y Z</div>
    </div>
  </div>
  <div class="row">
    <div class="digit asterisk" id="star" (click)="addDigit('*')"></div>
    <div class="digit" id="zero" (click)="addDigit('0')"></div>
    <div class="digit" id="hash" (click)="addDigit('#')"></div>
  </div>
  <div class="row">
    <div class="call-empty"></div>
    <div class="call" [ngClass]="{ 'enable-call-button': isValidPhoneNumber }" (click)="dialNumber()"></div>
    <div class="back-button" (click)="removeLastDigit()"></div>
  </div>
  <div></div>
</div>
