<div [ngClass]="'speed-dial-container'">
  <ng-template [ngIf]="showLoadingEffect">
    <app-loading [loaderType]="'gridLoader'"></app-loading>
  </ng-template>
  <div *ngIf="!showLoadingEffect" class="speed-dial-contacts">
    <ng-container *ngFor="let speedDialContact of speedDialContacts">
      <ng-template [ngIf]="!!speedDialContact.displayName">
        <div class="speed-dial-content">
          <app-contact [contact]="speedDialContact" class="contact-wrapper"></app-contact>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
