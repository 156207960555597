import { Injectable } from '@angular/core';
import { CloudAuthenticationService } from 'src/app/services/cloud-authentication.service';
import { AdminService, Config } from '@mitel/cloudlink-sdk';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { LoggingService } from 'src/app/services/logging.service';
import { Contact } from 'src/app/model/contact';
import { SpeedDial } from 'src/app/common/constants';

@Injectable({
  providedIn: 'root'
})
export class SpeeddialsCloudlinkService {
  adminService: AdminService;
  cloudLinkSpeedDials = [] as Contact[];

  constructor(
    private clservice: CloudAuthenticationService,
  ) {
    this.adminService = new AdminService({ url: environment.cloudlink.adminUrl, http: Config.http });
  }

  public async getCloudlinkSpeedDials(): Promise<any> {
    const params = {
      userId: this.clservice.principalId,
      tagId: SpeedDial.CLTagId,
      options: {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + this.clservice.accessToken,
        }
      }
    };
    return this.adminService.getUserTag(params);
  }

  public async createCloudlinkSpeedDials(body: object): Promise<any> {
    const speedDialBody = `{"${SpeedDial.CLTagId}": ${JSON.stringify(body)}}`;
    const params = {
      userId: this.clservice.principalId,
      body: speedDialBody,
      options: {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + this.clservice.accessToken,
        }
      }
    };
    return from(this.adminService.createUserTag(params).catch((error) => {
      LoggingService.error(`[SpeeddialsCloudlinkService.createCloudlinkSpeedDials]: Failed to createCloudlinkSpeedDials ${error.body}`);
    }));
  }
}
