import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as msal from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { permissions } from '../common/constants';
import { AuthState } from '../model/auth-state';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  window: Window;
  msalClient: msal.PublicClientApplication;
  request: msal.RedirectRequest | msal.SilentRequest;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
    LoggingService.info('[AuthService.constructor]: initialising msal client');
    const msalConfig = {
      auth: {
        clientId: environment.azureAD.clientId,
        redirectUri: this.window.origin,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false,
      }
    };

    // MSAL request object to use over and over
    this.request = {
      scopes: ['user.read'],
    };

    // Keep this MSAL client around to manage state across SPA "pages"
    this.msalClient = new msal.PublicClientApplication(msalConfig);
  }

  // Call this on every request to an authenticated page
  // Promise returns true if user is logged in, false if user is not
  async init(): Promise<string> {
    const response = await this.msalClient.handleRedirectPromise();
    let userName = '';
    if (response?.account.username) {
      LoggingService.info('[AuthService.init]: User is coming from microsoft login page');
      userName = response.account.username;
      this.setMSUserInfoToLocalStorage(response);
    } else {
      userName = this.getUsername();
    }
    LoggingService.info('[AuthService.init]: Username: ' + userName);
    return userName;
  }

  async isLoggedIn(): Promise<boolean> {
    const accessToken = await this.getAccessToken(permissions);
    return accessToken !== '';
  }

  getUsername(): string {
    return localStorage.getItem('msUsername') || '';
  }

  getUserId(): string {
    const accounts = this.msalClient.getAllAccounts();
    let result = null;

    if (accounts?.length === 1) {
      const oid = 'oid';
      result = accounts[0].idTokenClaims[oid];
    } else if (accounts && accounts.length > 1) {
      LoggingService.error('[AuthService.getUserId]: Multiple users logged in');
    }
    return result;
  }

  login(scopes: string[], loginHint = ''): void {
    if (scopes) {
      this.request.scopes = scopes;
      (this.request as msal.RedirectRequest).loginHint = loginHint;
    }
    try {
      LoggingService.info('[AuthService.login]: Redirecting to MS login page');
      this.msalClient.loginRedirect(this.request);
    } catch (err) {
      LoggingService.error(`[AuthService.getUsername]: Failed to redirect to microsoft login page ${err}`);
      throw new Error('Failed to redirect to microsoft login page');
    }
  }

  async getAccessToken(scopes: string[]): Promise<string> {
    LoggingService.info('[AuthService.getAccessToken]: getting access token');
    const accessTokenWithExpiry = await this.getAccessTokenWithExpiry(scopes);
    return accessTokenWithExpiry?.accessToken || '';
  }

  // Call this to get the username, access token, and expiration date
  async getAccessTokenWithExpiry(scopes: string[]): Promise<AuthState> {
    this.request.account = this.msalClient.getAccountByUsername(this.getUsername());
    if (!this.request.account) {
      return null;
    }
    this.msalClient.setActiveAccount(this.request.account);
    if (scopes) {
      this.request.scopes = scopes;
    }
    try {
      const requestCopy = Object.assign({}, this.request);
      delete requestCopy.account.idTokenClaims;
      LoggingService.info(
        `[AuthService.getAccessTokenWithExpiry]: calling msalClient.acquireTokenSilent; request: ${JSON.stringify(
          requestCopy
        )}`
      );
      const resp = await this.msalClient.acquireTokenSilent(this.request as msal.SilentRequest);
      if (resp?.accessToken) {
        LoggingService.info(`[AuthService.getAccessTokenWithExpiry]: msalClient.acquireTokenSilent was successful.`);
        this.setMSUserInfoToLocalStorage(resp);
        return new AuthState({
          username: this.getUsername(),
          accessToken: resp.accessToken,
          expiresOn: new Date(resp.expiresOn).getTime(),
        });
      } else {
        LoggingService.info('[AuthService.getAccessTokenWithExpiry]: msalClient.acquireTokenSilent returns null');
        return null;
      }
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        LoggingService.warning(
          `[AuthService.getAccessTokenWithExpiry]: msalClient.acquireTokenSilent throws msal.InteractionRequiredAuthError; acquiring token using redirect: ${JSON.stringify(
            error
          )}`
        );
        this.msalClient.acquireTokenRedirect(this.request);
      }
      LoggingService.error(
        `[AuthService.getAccessTokenWithExpiry]: msalClient.acquireTokenSilent throws error: ${JSON.stringify(error)}`
      );
      return null;
    }
  }

  private setMSUserInfoToLocalStorage(resp: any): void {
    localStorage.setItem('msUsername', resp?.account.username);
    localStorage.setItem('msName', resp?.account.name);
    localStorage.setItem('mslocalAccountId', resp?.account.localAccountId);
  }
}
